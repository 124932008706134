import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { ErrorHandlerService } from '../services/error-handler.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../services/error-handler.service";
var AddinvestigatorhttpService = /** @class */ (function () {
    function AddinvestigatorhttpService(http, errorHandlerService) {
        this.http = http;
        this.errorHandlerService = errorHandlerService;
        this.baseUrl = environment.apiUrl;
        this.httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' })
        };
    }
    AddinvestigatorhttpService.prototype.getAddInvestigatorData = function (sponProjectData) {
        var _this = this;
        var tempObj = {
            tempSponsorId: sponProjectData.sponsorData.id,
            tempProjectId: sponProjectData.projectData.id
        };
        var jsonRequest = JSON.stringify(tempObj, null, 2);
        var urlData = this.baseUrl + "/api/investigator/getRows";
        return this.http.post(urlData, jsonRequest, this.httpOptions)
            .pipe(catchError(function (error) {
            return _this.errorHandlerService.errorHandlerWithTranslator(error);
        }));
    };
    AddinvestigatorhttpService.prototype.getEditInvestigatorData = function (investigatorPid, projectId, sponsorId) {
        var _this = this;
        var urlData = this.baseUrl + "/api/investigator/getInvestigatorDetail";
        return this.http.get(urlData, {
            params: {
                investigatorId: investigatorPid,
                projectId: projectId,
                sponsorId: sponsorId
            }
        })
            .pipe(catchError(function (error) {
            return _this.errorHandlerService.errorHandlerWithTranslator(error);
        }));
    };
    AddinvestigatorhttpService.prototype.getInvestigatorDataExcel = function (sponProjectData) {
        var tempObj = {
            tempSponsorId: sponProjectData.sponsorData.id,
            tempProjectId: sponProjectData.projectData.id
        };
        var jsonRequest = JSON.stringify(tempObj, null, 2);
        var urlData = this.baseUrl + "/api/investigator/importInvestigatorExcel";
        return this.http.post(urlData, jsonRequest, {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
            responseType: 'blob'
        });
    };
    AddinvestigatorhttpService.prototype.saveInvestigatorDetail = function (investigatorData) {
        var _this = this;
        var jsonRequest = JSON.stringify(investigatorData, null, 2);
        var urlData = this.baseUrl + "/api/investigator/saveInvestigatorData";
        return this.http.post(urlData, jsonRequest, this.httpOptions)
            .pipe(catchError(function (error) {
            return _this.errorHandlerService.errorHandlerWithTranslator(error);
        }));
    };
    AddinvestigatorhttpService.prototype.updateInvestigatorDetail = function (investigatorData) {
        var _this = this;
        var jsonRequest = JSON.stringify(investigatorData, null, 2);
        var urlData = this.baseUrl + "/api/investigator/updateInvestigatorData";
        return this.http.post(urlData, jsonRequest, this.httpOptions)
            .pipe(catchError(function (error) {
            return _this.errorHandlerService.errorHandlerWithTranslator(error);
        }));
    };
    AddinvestigatorhttpService.prototype.deleteInvestigatorSite = function (sponsorProjectData) {
        var _this = this;
        var urlData = this.baseUrl + "/api/investigator/deleteInvestigatorSite";
        var jsonRequest = JSON.stringify(sponsorProjectData, null, 2);
        return this.http.post(urlData, jsonRequest, this.httpOptions)
            .pipe(catchError(function (error) {
            return _this.errorHandlerService.errorHandlerWithTranslator(error);
        }));
    };
    AddinvestigatorhttpService.prototype.sendInvestigatorSiteUserInviteByInvestigatorId = function (requestData) {
        var _this = this;
        var jsonRequest = JSON.stringify(requestData, null, 2);
        var urlData = this.baseUrl + "/api/investigator/sendInvestigatorInvite";
        return this.http.post(urlData, jsonRequest, this.httpOptions)
            .pipe(catchError(function (error) {
            return _this.errorHandlerService.errorHandlerWithTranslator(error);
        }));
    };
    AddinvestigatorhttpService.prototype.uploadFile = function (sponsorProjectData, file) {
        var data = new FormData();
        data.append('sponsorId', sponsorProjectData.sponsorData.id);
        data.append('projectId', sponsorProjectData.projectData.id);
        data.append('uploadedFile', file, file.name);
        var urlData = this.baseUrl + "/api/investigator/bulkUpload";
        return this.http.post(urlData, data, {
            responseType: 'json'
        });
    };
    AddinvestigatorhttpService.prototype.validateSiteAlreadyExistOrNot = function (siteNumber, projectId) {
        var _this = this;
        return this.http.post(environment.apiUrl + '/api/investigator/checkSiteAlreadyExistOrNot', null, {
            params: {
                siteNumber: siteNumber,
                projectId: projectId
            }
        }).pipe(catchError(function (error) {
            return _this.errorHandlerService.errorHandlerWithTranslator(error);
        }));
    };
    AddinvestigatorhttpService.prototype.findNewEmailAddressExistInBiosite = function (investigatorSiteNumber) {
        var _this = this;
        var params = new HttpParams().set("investigatorPid", investigatorSiteNumber);
        return this.http.get(environment.apiUrl + '/api/investigator/checkNewEmailExist', { params: params })
            .pipe(catchError(function (error) {
            return _this.errorHandlerService.errorHandlerWithTranslator(error);
        }));
    };
    AddinvestigatorhttpService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AddinvestigatorhttpService_Factory() { return new AddinvestigatorhttpService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.ErrorHandlerService)); }, token: AddinvestigatorhttpService, providedIn: "root" });
    return AddinvestigatorhttpService;
}());
export { AddinvestigatorhttpService };
