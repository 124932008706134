import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject, throwError, of } from "rxjs";
import { catchError, filter, take, switchMap } from "rxjs/operators";
import { LoginService } from "app/services/login.service";
import { TOKEN_NAME, REFRESH_TOKEN_NAME } from "app/services/auth.constant";
import { StorageService } from "app/services/storage.service";

@Injectable()
export class PortalHTTPInterceptor implements HttpInterceptor {

    private isRefreshing = false;

    // This Subject is used to pass current value to all subscriber. 
    // The logic behind this is to block all calls at the time of refreshing 
    // token and as soon as the new value is received it will pass that to 
    // all the blocked subscriber
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);


    constructor(private loginService: LoginService,private storageService: StorageService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        //We dont want to replace the authorozation token for token endpoints. This token is Basic token
        if (!req.url.endsWith('/oauth/token') && req.url.indexOf('/user/checkuniqueregnid')<0
            && req.url.indexOf('/api/user/reset')<0
            && req.url.indexOf('/api/user/forgotpassword')<0
            && req.url.indexOf('/api/user/adduser')<0
            && req.url.indexOf('/api/user/portalusername')<0
            && req.url.indexOf('/api/user/resendConfirmation')<0 
            && req.url.indexOf('/api/user/updateuser')<0 
            && req.url.indexOf('/api/security/getSecureAuthProperties')<0 
            && req.url.indexOf('/api/security/getIdleTimeProperties')<0
            && req.url.indexOf('/api/security/getAppEnvironment')<0
            && req.url.indexOf('/assets/')<0 
            && req.url.indexOf('/user/checkuniqueConfirmId')<0
            && req.url.indexOf('amazonaws.com')<0
            && req.url.indexOf('/api/security/loadBgVideo')<0
            ) 
             { 
            req = this.addToken(req, this.loginService.getAccessToken());
        }

        return next.handle(req).pipe(catchError((error: HttpErrorResponse) => {
            if (error && error.status === 401 && !req.url.endsWith('/oauth/token')) {
                return this.handle401Error(req, next);
            } else {
                return throwError(error);
            }
        }));
    }

    // Adding Bearer token to the request Header(Authorization)
    addToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
        return request.clone({
            setHeaders: {
                Authorization: `Bearer ${token}`
            }
        });
    }


    private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
        if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.refreshTokenSubject.next(null);

            return this.loginService.refreshToken().pipe(
                switchMap((token: any) => {
                    this.isRefreshing = false;
                    let accessToken: string = token[TOKEN_NAME];
                    let refreshToken: string = token[REFRESH_TOKEN_NAME];
                    this.loginService.setAccessToken(accessToken);
                    this.loginService.setRefreshToken(refreshToken);
                    this.loginService.initiateAccessToken(accessToken);
                    this.refreshTokenSubject.next(accessToken);
                    this.storageService.updateStorageOnOtherTab(new Date());
                    return next.handle(this.addToken(request, accessToken));
                }),
                catchError(err => {
                    this.loginService.navigateAndDestroyToken();
                    this.isRefreshing = false;
                    return throwError(err);
                })
            );

        } else {
            return this.refreshTokenSubject.pipe(
                filter(token => token != null),
                take(1),
                switchMap(jwt => {
                    return next.handle(this.addToken(request, jwt));
                }));
        }
    }

}