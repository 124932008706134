<div class="modal-content modal-content-small auto-width">
<div class="modal-header modal-block-primary">
    <button type="button" class="close" aria-hidden="true" (click)="bsModalRef.hide()">
        <i class="nc-icon nc-simple-remove"></i>
    </button>
    <h5 class="modal-title" id="specLabel"><b>{{'order.alert' | translate}}</b></h5>
  </div>
  <div class="modal-body clearfix adjustPadding">
    <div class="modal-text">
      <p>{{'order.orderid' | translate}} '{{orderid}}' {{'order.submitdisclaimer' | translate}}?</p>
    </div>
  </div>
  <div class="modal-footer adjustPadding">
    <button class="btn btn-neo-blue btn-round" (click)="action(true)">{{'order.yes' | translate}}</button>
    <button class="btn btn-neo-blue btn-round" (click)="action(false)">{{'order.no' | translate}}</button>
  </div>
</div>
