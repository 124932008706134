import Swal from 'sweetalert2';
import { throwError } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
var ErrorHandlerService = /** @class */ (function () {
    function ErrorHandlerService(translator) {
        this.errHead = "Error";
        this.butnOk = "OK";
        this.errTypeWar = "warning";
        this.translate = translator;
    }
    ErrorHandlerService.prototype.errorHandler = function (httpErrorResponse) {
        if (httpErrorResponse.status != 401 && httpErrorResponse.status != 403 && !httpErrorResponse.url.endsWith('/oauth/token')) {
            if (httpErrorResponse.error && httpErrorResponse.error.userMessage) {
                var errorMessage_1 = httpErrorResponse.error.userMessage;
                Swal.fire({
                    title: "Error",
                    text: errorMessage_1,
                    type: "warning",
                    confirmButtonText: "OK",
                    confirmButtonClass: "btn btn-danger"
                });
            }
            else {
                var erroHead = httpErrorResponse.error.error;
                var errorMessage = httpErrorResponse.error.message;
                Swal.fire({
                    title: erroHead != undefined ? erroHead.toString().length > 100 ? erroHead.toString().substring(1, 100) : erroHead.toString() : 'Error',
                    text: errorMessage != undefined ? errorMessage : 'Something went wrong please try again later.',
                    type: "error",
                    confirmButtonText: "OK",
                    confirmButtonClass: "btn btn-danger"
                });
            }
        }
        return throwError(httpErrorResponse);
    };
    /**
     * If the error response type is blob format call this method.
     * In this method extract the error text from blob and show the translated error message in popup.
     * @param httpErrorResponse
     */
    ErrorHandlerService.prototype.errorParseBlobtoJson = function (httpErrorResponse) {
        if (httpErrorResponse.error instanceof Blob) {
            var reader = new FileReader();
            reader.onload = (function (f) {
                return function (e) {
                    var parseJson = JSON.parse(e.target.result);
                    if (parseJson.userMessage) {
                        f.translate.get(parseJson.userMessage.trim()).subscribe(function (data) {
                            f.swalError_Warning(f.translate.instant(f.errHead), data, f.errTypeWar, f.translate.instant(f.butnOk));
                        });
                    }
                };
            })(this);
            reader.readAsText(httpErrorResponse.error);
        }
        return throwError(httpErrorResponse);
    };
    /**
     * In this method translate the runtime java exception error message and
     * showed in popup.If the "errorMessage" exist in translation json then
     * retun that key related translated text.Otherwise return the same text.
     * @param httpErrorResponse
     */
    ErrorHandlerService.prototype.errorHandlerWithTranslator = function (httpErrorResponse) {
        var _this = this;
        //Got a properly formatted Pharmaportal Exception with userMessage
        if (httpErrorResponse.status != 401 && httpErrorResponse.status != 403 && !httpErrorResponse.url.endsWith('/oauth/token')) {
            if (httpErrorResponse.error && httpErrorResponse.error.userMessage) {
                if (httpErrorResponse.error.userMessage.includes("locked by")) {
                    var lockedby = httpErrorResponse.error.userMessage.split(":");
                    var errorMessage = this.translate.instant("The order is locked by").replace("{username}", lockedby[1]);
                    this.swalError_Warning(httpErrorResponse.error.error, errorMessage, 'error', 'OK');
                }
                else {
                    var errorMessage = httpErrorResponse.error.userMessage.trim();
                    this.translate.get(errorMessage).subscribe(function (data) {
                        _this.swalError_Warning(_this.translate.instant(_this.errHead), data, _this.errTypeWar, _this.translate.instant(_this.butnOk));
                    });
                }
            }
            else {
                this.swalError_Warning(httpErrorResponse.error.error, httpErrorResponse.error.message, 'error', 'OK');
            }
        }
        return throwError(httpErrorResponse);
    };
    ErrorHandlerService.prototype.swalError_Warning = function (erroHead, errorMessage, errorType, okHead) {
        Swal.fire({
            title: erroHead != undefined ? erroHead.toString().length > 100 ? erroHead.toString().substring(1, 100) : erroHead.toString() : this.translate.instant('Error'),
            text: errorMessage != undefined ? errorMessage : this.translate.instant('SOMETHING_WENT_WRONG'),
            type: errorType,
            confirmButtonText: this.translate.instant(okHead),
            confirmButtonClass: "btn btn-danger"
        });
    };
    ErrorHandlerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ErrorHandlerService_Factory() { return new ErrorHandlerService(i0.ɵɵinject(i1.TranslateService)); }, token: ErrorHandlerService, providedIn: "root" });
    return ErrorHandlerService;
}());
export { ErrorHandlerService };
