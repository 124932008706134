import { OnDestroy, Injectable } from '@angular/core';
import { Subject } from "rxjs";
import { share } from 'rxjs/operators';
import { LoginService } from './login.service';
import { TOKEN_NAME, REFRESH_TOKEN_NAME, INTERNAL_USER_LOGOUT_URL } from './auth.constant';
import { SPONSORPROJECTDATA } from 'app/shared/pharmaportal.constant';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class StorageService implements OnDestroy {

    private identifier: string;
    private storage: Storage = localStorage;

    private storageSetupTime: Date;

    constructor(private router: Router) {
        this.identifier = Math.random().toString() + Date.now().toString();
        Object.keys(sessionStorage).forEach(key => {
            localStorage.setItem(key, sessionStorage[key]);
        });
        this.start();
    }

    ngOnDestroy() {
        this.stop();
    }

    public getItem(key: string, storage?: Storage): string {
        if (storage) { return storage.getItem(key); }
        return this.storage.getItem(key);
    }
    public setItem(key: string, data: string, storage?: Storage): void {
        if (storage) { 
            storage.setItem(key, data); 
        }
        this.storage.setItem(key, data);
        sessionStorage.setItem(key,data);
    }

    public removeItem(key: string, storage?: Storage): void {
        if (storage) {
            storage.removeItem(key);
        } else {
            this.storage.removeItem(key);
        }
        sessionStorage.removeItem(key);
    }


    private start(): void {
        let data = {
            id: this.identifier,
            host: window.location.host
        }
        window.addEventListener("storage", this.storageEventListener.bind(this));
        window.addEventListener("beforeunload", this.beforeUnload.bind(this));
    }
    private beforeUnload(){
        localStorage.setItem("unloading","true");
        localStorage.clear();
    }
    private canProceedTosendStorage(jsonStrig: string): boolean {
        let data = JSON.parse(jsonStrig);
        return (window.location.host == data.host && !(this.identifier == data.id))
    }
    private storageEventListener(event: StorageEvent) {
        if (event.storageArea == localStorage) {
            if (event.key == 'unloading' && event.newValue) {
                Object.keys(sessionStorage).forEach(key => {
                                localStorage.setItem(key, sessionStorage[key]);
                            });
            }
        }
    }

    public updateStorageOnOtherTab(newTime?:Date) {
        let timeNow = new Date();
        if(newTime){
            this.storageSetupTime = timeNow
        }
        let storageData = {
            timeStamp: timeNow,
            data: sessionStorage,
            host: window.location.host
        }
        localStorage.setItem('setSessionStorage', JSON.stringify(storageData));
        localStorage.removeItem('setSessionStorage');
    }

    public propagateNEwValue(key: string, value: string): void {
        let data = {
            key: key,
            value: value,
            host: window.location.host
        }
        localStorage.setItem('updateValue', JSON.stringify(data));
        localStorage.removeItem('updateValue');
    }

    private setNewValue(jsonString): void {
        let data = JSON.parse(jsonString);
        if (window.location.host == data.host) {
            this.storage.setItem(data.key, data.value);
        }
    }

    private stop(): void {
        window.removeEventListener("storage", this.storageEventListener.bind(this));
    }

}