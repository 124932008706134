import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  previousData = '';
  currentdata = null;
  orderId = '';
  islogout = false;
  orderLockFlag='N';
  constructor() { }
}
