// importing for logging capabilities
import { NgxLoggerLevel } from 'ngx-logger';
export const environment = {
  production: true,
  baseUrl: '.',
  //deployUrl: '/pharmaportal',
  apiUrl: '.',
  //appRootPrefix: '/<>',
  logLevel: NgxLoggerLevel.OFF,
  serverLogLevel: NgxLoggerLevel.ERROR,
  version: '2.4.1.3.3436' //DO NOT CHANGE THIS VALUE - Replaced on Build Server.
};
