import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';
import { catchError } from 'rxjs/operators';
import { ErrorHandlerService } from 'app/services/error-handler.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../services/error-handler.service";
var OrderService = /** @class */ (function () {
    function OrderService(http, errorHandlerService) {
        this.http = http;
        this.errorHandlerService = errorHandlerService;
        this.baseUrl = environment.apiUrl + "/api/onlineorder/";
        this.headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
    }
    OrderService.prototype.getFileValidationInfo = function () {
        return this.http.get(this.baseUrl + "getfilevalidationdetails");
    };
    OrderService.prototype.deleteFile = function (orderId, fileName) {
        return this.http.get(this.baseUrl + "deleteFile/" + orderId + "/" + fileName);
    };
    OrderService.prototype.downloadFile = function (sponsorId, projectId, orderId, fileName) {
        return this.http.get(this.baseUrl + "downloadFile/" + sponsorId + "/" + projectId + "/" + orderId + "/" + fileName);
    };
    OrderService.prototype.uploadDocuments = function (files) {
        return this.http.post(this.baseUrl + "uploadDocuments/", files, {
            responseType: 'json'
        });
    };
    OrderService.prototype.getDocumentList = function (orderId) {
        return this.http.get(this.baseUrl + "getDocuments/" + orderId);
    };
    OrderService.prototype.getGender = function () {
        return this.http.get(this.baseUrl + "getgenderlist");
    };
    OrderService.prototype.getSponsors = function (uname, utype) {
        return this.http.get(this.baseUrl + "getsitesforuser");
    };
    OrderService.prototype.getSponsorsList = function () {
        return this.http.get(this.baseUrl + "sponsor");
    };
    OrderService.prototype.getProjectList = function () {
        return this.http.get(this.baseUrl + "project");
    };
    OrderService.prototype.getSponsorByProject = function (projectid) {
        return this.http.get(this.baseUrl + "project/" + projectid + "/sponsor");
    };
    OrderService.prototype.getIfaccessThere = function (projectId) {
        return this.http.get(this.baseUrl + "ifprojectaccessthere/" + projectId).pipe(catchError(this.errorHandlerService.errorHandler));
    };
    OrderService.prototype.getProjectBySponsor = function (sponsorid) {
        return this.http.get(this.baseUrl + "sponsor/" + sponsorid + "/project");
    };
    OrderService.prototype.getInvestigatorByProject = function (projectid) {
        return this.http.get(this.baseUrl + "project/" + projectid + "/investigator");
    };
    OrderService.prototype.getPdfManifest = function (orderID, fromWhere) {
        var httpOptions = {
            responseType: 'arraybuffer'
        };
        return this.http.get(this.baseUrl + "pdfmanifest/" + orderID + "/" + fromWhere, httpOptions);
    };
    OrderService.prototype.getVisits = function (pid) {
        return this.http.get(this.baseUrl + "getlistofprojectvisits?projectid=" + pid);
    };
    OrderService.prototype.getIndication = function (pid) {
        return this.http.get(this.baseUrl + "getlistofprojectIndications?projectid=" + pid);
    };
    OrderService.prototype.getCourierType = function () {
        return this.http.get(this.baseUrl + "getlistofcouriertype");
    };
    OrderService.prototype.getShippingTo = function () {
        return this.http.get(this.baseUrl + "getlistofshipto");
    };
    OrderService.prototype.getTestCode = function (pid) {
        return this.http.get(this.baseUrl + "getlistoftestcodes?projectid=" + pid);
    };
    OrderService.prototype.getSpecimenType = function () {
        return this.http.get(this.baseUrl + "getlistofsampletypes");
    };
    OrderService.prototype.getBodySite = function () {
        return this.http.get(this.baseUrl + "getbodysitelist");
    };
    OrderService.prototype.getSpecimenOrigin = function () {
        return this.http.get(this.baseUrl + "getsampleoriginlist");
    };
    OrderService.prototype.getSpecimenInfo = function () {
        return this.http.get(this.baseUrl + "getsampleinfolist");
    };
    OrderService.prototype.saveOrderDetails = function (fromWhere, order) {
        order.fromWhere = fromWhere;
        return this.http.post(this.baseUrl + "saveorderdetails", JSON.stringify(order), {
            headers: this.headers
        });
    };
    OrderService.prototype.saveInvOrderDetails = function (fromWhere, order) {
        order.fromWhere = fromWhere;
        return this.http.post(this.baseUrl + "saveorderdetails", JSON.stringify(order), {
            headers: this.headers
        });
    };
    OrderService.prototype.saveTestDetails = function (specimen) {
        return this.http.post(this.baseUrl + "savespecimen", JSON.stringify(specimen), {
            headers: this.headers
        });
    };
    OrderService.prototype.getOrderByID = function (orderID) {
        return this.http.get(this.baseUrl + "getorder/" + orderID);
    };
    OrderService.prototype.deleteSpecimen = function (specimen) {
        return this.http.post(this.baseUrl + "deletespecimen/", JSON.stringify(specimen), {
            headers: this.headers
        });
    };
    OrderService.prototype.getSampleContainerType = function () {
        return this.http.get(this.baseUrl + "getlistofsamplecontainertypes/");
    };
    OrderService.prototype.getListOfTestcodeSampleContainerTypesProject = function (sampleTypeId, containerTypeId, projectId) {
        return this.http.get(this.baseUrl + "gettestcodesforprojectsamplecontainertypes/", { params: { sampleTypeId: sampleTypeId, containerTypeId: containerTypeId, projectId: projectId } });
    };
    OrderService.prototype.getListOfTestcodeSampleContainerTypesProjectVisit = function (sampleTypeId, containerTypeId, projectId, visit) {
        return this.http.get(this.baseUrl + "gettestcodesforprojectsamplecontainertypesvisit/", { params: { sampleTypeId: sampleTypeId, containerTypeId: containerTypeId, projectId: projectId, visitId: visit } });
    };
    OrderService.prototype.getListOfTestcodeSampleContainerTypesProjectIndication = function (sampleTypeId, containerTypeId, projectId, indication) {
        return this.http.get(this.baseUrl + "gettestcodesforprojectsamplecontainertypesindication/", { params: { sampleTypeId: sampleTypeId, containerTypeId: containerTypeId, projectId: projectId, indication: indication } });
    };
    OrderService.prototype.getDemographicfields = function (projectpid) {
        return this.http.get(this.baseUrl + "getdemographicfields?projectpid=" + projectpid);
    };
    OrderService.prototype.getFixationtimes = function () {
        return this.http.get(this.baseUrl + "getfixationtime");
    };
    OrderService.prototype.getInjectionStatus = function () {
        return this.http.get(this.baseUrl + "getinjectionstatus");
    };
    OrderService.prototype.getTissueExcision = function () {
        return this.http.get(this.baseUrl + "gettissueexcision");
    };
    OrderService.prototype.getAdjustedDate = function (date) {
        var newdate = new Date(date.getTime() + (60000 * date.getTimezoneOffset()));
        return newdate;
    };
    OrderService.prototype.isPageAccessible = function (params) {
        return this.http.get(environment.apiUrl + '/api/security/isPageAllowed', { params: params }).pipe(catchError(this.errorHandlerService.errorHandler));
    };
    OrderService.prototype.sortList = function (list, sortField) {
        return list.sort(function (a, b) { return a[sortField] && b[sortField] ? a[sortField].localeCompare(b[sortField]) : 0; });
    };
    OrderService.prototype.releaseOrderLock = function (formData) {
        return this.http.post(this.baseUrl + "releaseOrder", formData);
    };
    OrderService.prototype.getInvcat = function (category) {
        return this.http.get(this.baseUrl + "getInvcat/" + category);
    };
    OrderService.prototype.getInvTissueType = function () {
        return this.http.get(this.baseUrl + "getInvTissueType");
    };
    OrderService.prototype.getInvSpecimenType = function () {
        return this.http.get(this.baseUrl + "getlistofInvsampletypes");
    };
    OrderService.prototype.getInvSampleTransportType = function () {
        return this.http.get(this.baseUrl + "getInivataSampleTransportType");
    };
    OrderService.prototype.getInvestigatorNameBySieId = function (siteid) {
        return this.http.get(this.baseUrl + "investigator/" + siteid);
    };
    OrderService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OrderService_Factory() { return new OrderService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.ErrorHandlerService)); }, token: OrderService, providedIn: "root" });
    return OrderService;
}());
export { OrderService };
