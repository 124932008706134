import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { of } from 'rxjs';
import { shareReplay, catchError, map } from 'rxjs/operators';
import { ErrorHandlerService } from '../../../services/error-handler.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../services/error-handler.service";
var CACHE_SIZE = 1;
var SponsorProjectDropdownService = /** @class */ (function () {
    function SponsorProjectDropdownService(http, errorHandlerService) {
        this.http = http;
        this.errorHandlerService = errorHandlerService;
    }
    SponsorProjectDropdownService.prototype.getSponsorProjectDropdownData = function (selectVendorSponsor) {
        var urlData = "";
        if (selectVendorSponsor) {
            urlData = environment.apiUrl + "/api/sponsor/getSponsorProjectDropdownData?selectVendorSponsor=" + selectVendorSponsor;
        }
        else {
            urlData = environment.apiUrl + "/api/sponsor/getSponsorProjectDropdownData";
        }
        this.sponsorProjectDropdownData = this.http.post(urlData, null);
        return this.sponsorProjectDropdownData;
    };
    SponsorProjectDropdownService.prototype.getSelectedSponsorProjectData = function () {
        var urlData = environment.apiUrl + "/api/sponsor/getSelectedSponsorProjectData";
        return this.http.get(urlData).pipe(catchError(this.errorHandlerService.errorHandler));
    };
    SponsorProjectDropdownService.prototype.resetObservable = function () {
        this.cache$ = null;
    };
    Object.defineProperty(SponsorProjectDropdownService.prototype, "getSponsorProjectDetail", {
        // Here check observerable undefined then load the data from server otherwise get from Observable variable. 
        /**
         * During refresh the browser data clear in Observable variable that time save the dropdown sponsor Project detail in session storage
         * once after referesh recall the service get the data from session storage load in to observable variable remove the session variable.
         */
        get: function () {
            var sessionSpnsorProjectData = sessionStorage.getItem("sponsorProjectData");
            if (!this.cache$ && (!sessionSpnsorProjectData || sessionSpnsorProjectData == null || sessionSpnsorProjectData == "undefined")) {
                this.cache$ = this.requestSponsorProjectDetail().pipe(shareReplay(CACHE_SIZE));
            }
            else if (!this.cache$ && sessionSpnsorProjectData) {
                this.cache$ = of(JSON.parse(sessionSpnsorProjectData)).pipe(shareReplay(CACHE_SIZE));
                sessionStorage.removeItem("sponsorProjectData");
            }
            return this.cache$;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SponsorProjectDropdownService.prototype, "getSponsorProjectDataStatus", {
        /**
         * Here this service call every page get the data from  "this.cache$" varibale check if it null load the data from database
         * otherwise check if data empty retrun false otherwise true depends on condition hide or show the page.
         */
        get: function () {
            var _this = this;
            var sessionSpnsorProjectData = sessionStorage.getItem("sponsorProjectData");
            if (!this.cache$ && (!sessionSpnsorProjectData || sessionSpnsorProjectData == null || sessionSpnsorProjectData == "undefined")) {
                this.cache$ = this.requestSponsorProjectDetail().pipe(shareReplay(CACHE_SIZE));
            }
            else if (!this.cache$ && sessionSpnsorProjectData) {
                this.cache$ = of(JSON.parse(sessionSpnsorProjectData)).pipe(shareReplay(CACHE_SIZE));
                sessionStorage.removeItem("sponsorProjectData");
            }
            return this.cache$.pipe(map(function (data) {
                return _this.checkSponsorDataEmptyOrNot(data);
            }));
        },
        enumerable: true,
        configurable: true
    });
    SponsorProjectDropdownService.prototype.requestSponsorProjectDetail = function () {
        return this.http.post(environment.apiUrl + '/api/sponsor/getSponsorProjectDropdownData', null);
    };
    SponsorProjectDropdownService.prototype.returnSponsorDropDownCache = function () {
        return this.cache$;
    };
    SponsorProjectDropdownService.prototype.checkObservaEmpty = function () {
        return this.cache$ ? true : false;
    };
    //this part of code check if data empty or not depends return true or false.
    SponsorProjectDropdownService.prototype.checkSponsorDataEmptyOrNot = function (data) {
        var tempSponsorProjectStatus = true;
        if (data) {
            var tempSponsorValue = data.sponsors;
            if (tempSponsorValue.length > 0) {
                tempSponsorProjectStatus = tempSponsorValue[0].id ? true : false;
            }
            else {
                tempSponsorProjectStatus = false;
            }
        }
        else {
            tempSponsorProjectStatus = false;
        }
        return tempSponsorProjectStatus;
    };
    SponsorProjectDropdownService.prototype.getSponsorProjectDataByReportId = function (reportId) {
        var _this = this;
        var urlData = environment.apiUrl + "/api/data/getDataExportReqSpon";
        return this.http.get(urlData, {
            params: {
                reqReportId: reportId,
            }
        })
            .pipe(catchError(function (error) {
            return _this.errorHandlerService.errorHandlerWithTranslator(error);
        }));
    };
    SponsorProjectDropdownService.prototype.getSponsorProjectDataByReportId_BulkDownload = function (reportId) {
        var _this = this;
        var urlData = environment.apiUrl + "/api/bulkDownload/getSponsorProjectData";
        return this.http.get(urlData, {
            params: {
                reqReportId: reportId,
            }
        })
            .pipe(catchError(function (error) {
            return _this.errorHandlerService.errorHandlerWithTranslator(error);
        }));
    };
    SponsorProjectDropdownService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SponsorProjectDropdownService_Factory() { return new SponsorProjectDropdownService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.ErrorHandlerService)); }, token: SponsorProjectDropdownService, providedIn: "root" });
    return SponsorProjectDropdownService;
}());
export { SponsorProjectDropdownService };
