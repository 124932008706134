import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { OrderDetails, SpecimenResponse, OrderViewResponse, Test, UploadedFileList } from 'app/order/order.model';
import { environment } from 'environments/environment';
import { catchError } from 'rxjs/operators';
import { ErrorHandlerService } from 'app/services/error-handler.service';
import { InvOrderDetails } from 'app/invorder/invorder.model';

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  
  baseUrl = environment.apiUrl + "/api/onlineorder/";
  headers = new HttpHeaders({
    'Content-Type': 'application/json'
  });

  constructor(private http: HttpClient,private errorHandlerService:ErrorHandlerService) { }
  
  getFileValidationInfo() {
    return this.http.get<any>(`${this.baseUrl}getfilevalidationdetails`);

  }
  deleteFile(orderId, fileName) {
    return this.http.get<any>(`${this.baseUrl}deleteFile/${orderId}/${fileName}`);

  }

  downloadFile(sponsorId, projectId, orderId, fileName) {
    return this.http.get<any>(`${this.baseUrl}downloadFile/${sponsorId}/${projectId}/${orderId}/${fileName}`);
  }

  uploadDocuments(files) : Observable<UploadedFileList>{
    return this.http.post<UploadedFileList>(`${this.baseUrl}uploadDocuments/`, files, {        
      responseType: 'json'
    })
  }

  getDocumentList(orderId) {
    return this.http.get<Array<any>>(`${this.baseUrl}getDocuments/${orderId}`);
   
  }
  getGender() {
    return this.http.get(`${this.baseUrl}getgenderlist`)
  }

  getSponsors(uname, utype) {
    return this.http.get(`${this.baseUrl}getsitesforuser`);
  }

  getSponsorsList() {
    return this.http.get(`${this.baseUrl}sponsor`);
  }

  getProjectList() {
    return this.http.get(`${this.baseUrl}project`);
  }

  getSponsorByProject(projectid: String ) {
    return this.http.get(`${this.baseUrl}project/${projectid}/sponsor`);
  }

  getIfaccessThere(projectId){
    return this.http.get<Boolean>(`${this.baseUrl}ifprojectaccessthere/${projectId}`).pipe(
      catchError(this.errorHandlerService.errorHandler)
    );
  }
  
  getProjectBySponsor(sponsorid: String ) {
    return this.http.get(`${this.baseUrl}sponsor/${sponsorid}/project`);
  }

  getInvestigatorByProject(projectid: String ) {
    return this.http.get(`${this.baseUrl}project/${projectid}/investigator`);
  }

  getPdfManifest(orderID: string, fromWhere: string): any {
    const httpOptions = {
      responseType: 'arraybuffer' as 'json'
    };
    return this.http.get<any>(`${this.baseUrl}pdfmanifest/${orderID}/${fromWhere}`, httpOptions);
  }

  getVisits(pid) {
    return this.http.get(`${this.baseUrl}getlistofprojectvisits?projectid=${pid}`);
  }

  getIndication(pid) {
    return this.http.get(`${this.baseUrl}getlistofprojectIndications?projectid=${pid}`);
  }

  getCourierType() {
    return this.http.get(`${this.baseUrl}getlistofcouriertype`);
  }

  getShippingTo() {
    return this.http.get(`${this.baseUrl}getlistofshipto`);
  }

  getTestCode(pid):Observable<Test[]> {
    return this.http.get<Test[]>(`${this.baseUrl}getlistoftestcodes?projectid=${pid}`);
  }

  getSpecimenType() {
    return this.http.get(`${this.baseUrl}getlistofsampletypes`);
  }

  getBodySite() {
    return this.http.get(`${this.baseUrl}getbodysitelist`);
  }

  getSpecimenOrigin() {
    return this.http.get(`${this.baseUrl}getsampleoriginlist`);
  }

  getSpecimenInfo() {
    return this.http.get(`${this.baseUrl}getsampleinfolist`);
  }

  saveOrderDetails(fromWhere: string, order: OrderDetails): Observable<OrderDetails> {
    order.fromWhere = fromWhere;
    return this.http.post<OrderDetails>(`${this.baseUrl}saveorderdetails`, JSON.stringify(order), {
      headers: this.headers
    });
  } 

  saveInvOrderDetails(fromWhere: string, order: InvOrderDetails): Observable<InvOrderDetails> {
    order.fromWhere = fromWhere;
    return this.http.post<InvOrderDetails>(`${this.baseUrl}saveorderdetails`, JSON.stringify(order), {
      headers: this.headers
    });
  }

  saveTestDetails(specimen: SpecimenResponse): Observable<SpecimenResponse> {
    return this.http.post<SpecimenResponse>(`${this.baseUrl}savespecimen`, JSON.stringify(specimen), {
      headers: this.headers
    });
  }

  getOrderByID(orderID: string):Observable<OrderViewResponse>{
    return this.http.get<OrderViewResponse>(`${this.baseUrl}getorder/${orderID}`);
  }

  deleteSpecimen(specimen: SpecimenResponse):Observable<any>{
    return this.http.post<Observable<any>>(`${this.baseUrl}deletespecimen/`, JSON.stringify(specimen) ,{
      headers: this.headers
    });
  }

  getSampleContainerType() {
    return this.http.get<Observable<any>>(`${this.baseUrl}getlistofsamplecontainertypes/`);
  }


  getListOfTestcodeSampleContainerTypesProject(sampleTypeId,containerTypeId,projectId) {
    return this.http.get<any[]>(`${this.baseUrl}gettestcodesforprojectsamplecontainertypes/`,
    {params : {sampleTypeId : sampleTypeId, containerTypeId: containerTypeId, projectId: projectId}});
  }

  getListOfTestcodeSampleContainerTypesProjectVisit(sampleTypeId,containerTypeId,projectId, visit) {
    return this.http.get<any[]>(`${this.baseUrl}gettestcodesforprojectsamplecontainertypesvisit/`,
    {params : {sampleTypeId : sampleTypeId, containerTypeId: containerTypeId, projectId: projectId, visitId: visit }});
  }

  getListOfTestcodeSampleContainerTypesProjectIndication(sampleTypeId,containerTypeId,projectId, indication) {
    return this.http.get<any[]>(`${this.baseUrl}gettestcodesforprojectsamplecontainertypesindication/`,
    {params : {sampleTypeId : sampleTypeId, containerTypeId: containerTypeId, projectId: projectId, indication: indication }});
  }

  getDemographicfields(projectpid) {
    return this.http.get<Test[]>(`${this.baseUrl}getdemographicfields?projectpid=${projectpid}`);
  }

  getFixationtimes() {
    return this.http.get<Test[]>(`${this.baseUrl}getfixationtime`);
  }

  getInjectionStatus() {
    return this.http.get<Test[]>(`${this.baseUrl}getinjectionstatus`);
  }

  getTissueExcision() {
    return this.http.get<Test[]>(`${this.baseUrl}gettissueexcision`);
  }

  getAdjustedDate(date: Date): Date {
    let newdate = new Date(date.getTime() + (60000*date.getTimezoneOffset()));
    return newdate;

  }
  
  isPageAccessible(params: HttpParams){
    return this.http.get<boolean>(environment.apiUrl+'/api/security/isPageAllowed',{params}).pipe(
      catchError(this.errorHandlerService.errorHandler)
    );
  }

  sortList(list: any[], sortField: string) {
    return list.sort((a, b) => a[sortField] && b[sortField] ?a[sortField].localeCompare(b[sortField]): 0);
  }

  releaseOrderLock(formData: FormData){
    return this.http.post<void>(`${this.baseUrl}releaseOrder`,formData);
  }

  getInvcat(category: string) {
    return this.http.get(`${this.baseUrl}getInvcat/${category}`)
  }

  getInvTissueType(){
    return this.http.get(`${this.baseUrl}getInvTissueType`)
  }

  getInvSpecimenType() {
    return this.http.get(`${this.baseUrl}getlistofInvsampletypes`);
  }

  getInvSampleTransportType() {
    return this.http.get<Observable<any>>(`${this.baseUrl}getInivataSampleTransportType`);
  }

  getInvestigatorNameBySieId(siteid: String ) {
    return this.http.get(`${this.baseUrl}investigator/${siteid}`);
  }
}
