import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import {InvestigatormodelListService} from '../shared/models/investigatormodel-list.service';
import  {AddinvestigatorhttpService} from './addinvestigatorhttp.service';

@Injectable({
  providedIn: 'root'
})
export class AddinvestigatorService {

  private closeSideBarAlert = new Subject<boolean>();

  closeSideBarAlert$ =this.closeSideBarAlert.asObservable();

  constructor(public  addinvestigatorhttpService: AddinvestigatorhttpService) { }

  getAddInvestigatorData(sponProjectData: any): Observable<InvestigatormodelListService> {
       return this.addinvestigatorhttpService.getAddInvestigatorData(sponProjectData);
  }

  getEditInvestigatorData(requestParams: any, projectId: string, sponsorId: string): Observable<any> {
    return this.addinvestigatorhttpService.getEditInvestigatorData(requestParams, projectId, sponsorId);
}
getInvestigatorDataExcel(sponProjectData: any): Observable<InvestigatormodelListService> {
  return this.addinvestigatorhttpService.getInvestigatorDataExcel(sponProjectData);
}

getUpdateInvestigatorData(requestParams: any): Observable<any> {
  return this.addinvestigatorhttpService.updateInvestigatorDetail(requestParams);
}

  closeSideBarDetail(flagStatus: boolean){
    this.closeSideBarAlert.next(flagStatus);
  }

  saveInvestigatorDetail(investigatorData: any){
    return this.addinvestigatorhttpService.saveInvestigatorDetail(investigatorData);
  }

  deleteInvestigatorSite(sponsorProjectData: any){
    return this.addinvestigatorhttpService.deleteInvestigatorSite(sponsorProjectData);
  }

  sendInvestigatorSiteUserInviteByInvestigatorId(requestData: Object){
    return this.addinvestigatorhttpService.sendInvestigatorSiteUserInviteByInvestigatorId(requestData);
  }

  uploadFile(sponsorProjectData ,file: File): Observable<any>{
    return this.addinvestigatorhttpService.uploadFile(sponsorProjectData , file);
  }

  validateSiteAlreadyExistOrNot(siteNumber : string, projectId :string){
    return this.addinvestigatorhttpService.validateSiteAlreadyExistOrNot(siteNumber, projectId);
  }

  findNewEmailAddressExistInBiosite(investigatorSiteNumber: any){
    return this.addinvestigatorhttpService.findNewEmailAddressExistInBiosite(investigatorSiteNumber);
  }
}
