import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { catchError } from 'rxjs/operators';
import { ErrorHandlerService } from 'app/services/error-handler.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../services/error-handler.service";
var UserService = /** @class */ (function () {
    function UserService(http, errorHandlerService) {
        this.http = http;
        this.errorHandlerService = errorHandlerService;
        this._jsonURL = 'assets/timeZone.json';
    }
    UserService.prototype.getUserProfile = function () {
        return this.http.get(environment.apiUrl + "/api/user/users/getUserProfile");
    };
    UserService.prototype.updateUserProfile = function (updateUser) {
        var urlData = environment.apiUrl + "/api/user/updateUserProfile";
        return this.http.put(urlData, updateUser).pipe(catchError(this.errorHandlerService.errorHandler));
    };
    UserService.prototype.getJSON = function () {
        return this.http.get(this._jsonURL);
    };
    UserService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserService_Factory() { return new UserService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.ErrorHandlerService)); }, token: UserService, providedIn: "root" });
    return UserService;
}());
export { UserService };
