/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../shared/footer/footer.component.ngfactory";
import * as i2 from "../../shared/footer/footer.component";
import * as i3 from "@ngx-translate/core";
import * as i4 from "../../sidebar/sidebar.component.ngfactory";
import * as i5 from "../../sidebar/sidebar.component";
import * as i6 from "../../services/login.service";
import * as i7 from "@angular/router";
import * as i8 from "../../shared/navbar/navbar.component.ngfactory";
import * as i9 from "../../shared/navbar/navbar.component";
import * as i10 from "../../app.component";
import * as i11 from "@angular/common";
import * as i12 from "../../addinvestigator/addinvestigator.service";
import * as i13 from "../../services/userconnection.service";
import * as i14 from "../../services/storage.service";
import * as i15 from "../../order/order.service";
import * as i16 from "../../services/event-bus.service";
import * as i17 from "@angular/common/http";
import * as i18 from "../../shared/fixedplugin/fixedplugin.component.ngfactory";
import * as i19 from "../../shared/fixedplugin/fixedplugin.component";
import * as i20 from "./admin-layout.component";
var styles_AdminLayoutComponent = [];
var RenderType_AdminLayoutComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AdminLayoutComponent, data: {} });
export { RenderType_AdminLayoutComponent as RenderType_AdminLayoutComponent };
function View_AdminLayoutComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "footer-cmp", [], null, null, null, i1.View_FooterComponent_0, i1.RenderType_FooterComponent)), i0.ɵdid(2, 49152, null, 0, i2.FooterComponent, [i3.TranslateService], null, null)], null, null); }
export function View_AdminLayoutComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(671088640, 1, { sidebar: 0 }), i0.ɵqud(671088640, 2, { navbar: 0 }), (_l()(), i0.ɵeld(2, 0, null, null, 10, "div", [["class", "wrapper"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "div", [["class", "sidebar cust-sidebar-style"], ["data-active-color", "danger"], ["data-color", "brown"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "sidebar-cmp", [], null, null, null, i4.View_SidebarComponent_0, i4.RenderType_SidebarComponent)), i0.ɵdid(5, 114688, null, 0, i5.SidebarComponent, [i6.LoginService, i7.Router], null, null), (_l()(), i0.ɵeld(6, 0, null, null, 6, "div", [["class", "main-panel"]], null, [[null, "scroll"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("scroll" === en)) {
        var pd_0 = (_co.onScroll($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 1, "navbar-cmp", [], null, null, null, i8.View_NavbarComponent_0, i8.RenderType_NavbarComponent)), i0.ɵdid(8, 245760, [[2, 4]], 0, i9.NavbarComponent, [i10.AppComponent, i11.Location, i0.Renderer, i0.ElementRef, i7.Router, i12.AddinvestigatorService, i6.LoginService, i3.TranslateService, i13.UserConnectionService, i14.StorageService, i15.OrderService, i16.EventBusService, i17.HttpClient], null, null), (_l()(), i0.ɵeld(9, 16777216, null, null, 1, "router-outlet", [], null, [[null, "activate"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("activate" === en)) {
        var pd_0 = (_co.onActivateRoute($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(10, 212992, null, 0, i7.RouterOutlet, [i7.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, { activateEvents: "activate" }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AdminLayoutComponent_1)), i0.ɵdid(12, 16384, null, 0, i11.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(13, 0, null, null, 1, "fixedplugin-cmp", [], null, null, null, i18.View_FixedPluginComponent_0, i18.RenderType_FixedPluginComponent)), i0.ɵdid(14, 114688, null, 0, i19.FixedPluginComponent, [], null, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 5, 0); _ck(_v, 8, 0); _ck(_v, 10, 0); var currVal_0 = !_co.isMap(); _ck(_v, 12, 0, currVal_0); _ck(_v, 14, 0); }, null); }
export function View_AdminLayoutComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-layout", [], null, null, null, View_AdminLayoutComponent_0, RenderType_AdminLayoutComponent)), i0.ɵdid(1, 4308992, null, 0, i20.AdminLayoutComponent, [i7.Router, i11.Location, i16.EventBusService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AdminLayoutComponentNgFactory = i0.ɵccf("app-layout", i20.AdminLayoutComponent, View_AdminLayoutComponent_Host_0, {}, {}, []);
export { AdminLayoutComponentNgFactory as AdminLayoutComponentNgFactory };
