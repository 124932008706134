import { Component, OnInit, Injectable } from '@angular/core';

import { AppComponent } from '../app.component';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { LoginService } from 'app/services/login.service';
import { UserService } from './user.service';
import { TranslateService } from '@ngx-translate/core';
import { UserProfile } from './userprofile.model';
import { HttpClient } from '@angular/common/http';
import { StorageService } from 'app/services/storage.service';
import { LANGUAGE_DISCLAIMER_TEXT } from 'app/shared/pharmaportal.constant';

declare var $:any;
@Component({
    selector: 'user-cmp',
    templateUrl: 'user.component.html',
    styleUrls: ['user.component.css']
})
@Injectable({
    providedIn: 'root'
})
export class UserComponent implements OnInit{
  
    public lang : any;
    public user?: UserProfile;  
    private httpOptions: any;
    public selectedLanguage;
    public timeZones: string[]=[];
    timeZone: string = new Date().toTimeString().slice(19 , (new Date().toTimeString().length) -1);
 
  constructor(public mainApp: AppComponent, private translator: TranslateService, 
    private router: Router, private loginService: LoginService, 
    private userService : UserService,private http: HttpClient,
    private storageService: StorageService) {
      this.userService.getUserProfile().subscribe(response=>{
        this.user=response;
        this.user.timezone!==null? this.timeZone=this.user.timezone: this.timeZone=this.timeZone;
        this.selectedLanguage = this.mainApp.getLanguageFromCode(this.user.locale);
      });
      this.userService.getJSON().subscribe(data => {
        
        data.forEach(element => {
          this.timeZones.push(element);
        });
       });
      
    }
    
  showSwal(titile: string){    
    Swal.fire({
      title: titile,
      toast: true,
      position: 'top',
      timer: 3000,
      type: "success",
      showConfirmButton: false
    })
  }

  ngOnInit() {
        
  }
    
  save(userProfile: UserProfile, isValid: boolean) {
    userProfile.locale = this.user.locale;
    userProfile.timezone=this.timeZone;
    
    userProfile.portaluserName = this.loginService.getLoggedInUserName();
    if (isValid) {
       this.userService.updateUserProfile(userProfile).subscribe((response)=>{
         this.showSwal(this.translator.instant("Saved Succesfully"));
         this.mainApp.switchLang(userProfile.locale);       
        });
    }
  }

  cancel(){


    Swal.fire({
      text: this.translator.instant("Page Leave alert"),
      type: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      cancelButtonText: this.translator.instant("Cancel"),
      confirmButtonText: this.translator.instant("Yes"),
      cancelButtonClass: 'btn btn-sucess',
      confirmButtonClass: "btn btn-danger"
    }).then((result) => {
      if (result.value) {
        this.router.navigate(['/reports/maindashboard']);
      }
    })
  }  
  
  public changeLanguage(){
    
    let languageCode = this.mainApp.getCodeFromLanguage(this.selectedLanguage);
    
    this.user.locale = languageCode;
    Swal.fire(
      'Disclaimer!',
      LANGUAGE_DISCLAIMER_TEXT,
      'warning'
    );
  }

  public localStorageItem(id: string): string {
    return this.storageService.getItem(id);
  }
 }
