import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SidebarComponent } from './sidebar.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import {HttpClientModule } from '@angular/common/http';
import  {SharedModule} from  '../shared/shared.module';
import { sideBarScrollDirective } from './sidebarScroll.directive';

@NgModule({
    imports: [ RouterModule, CommonModule, NgbModule ,
    
        HttpClientModule,
        SharedModule
    
    ],
    declarations: [ SidebarComponent,sideBarScrollDirective ],
    exports: [ SidebarComponent ],

})

export class SidebarModule {}


