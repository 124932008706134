import { Keepalive } from '@ng-idle/keepalive';
import { EventTargetInterruptSource, Idle } from '@ng-idle/core';
import { ElementRef, OnDestroy } from '@angular/core';
import Swal from 'sweetalert2';
import { HttpClient } from '@angular/common/http';
import { LicenseManager } from "ag-grid-enterprise";
import { TOKEN_NAME, REFRESH_TOKEN_NAME } from './services/auth.constant';
import { TranslateService } from '@ngx-translate/core';
import { LANGUAGES } from './shared/pharmaportal.constant';
import { LANGUAGECODES } from './shared/pharmaportal.constant';
import { LANGUAGE_DISCLAIMER_TEXT } from './shared/pharmaportal.constant';
import { UserService } from "../app/userpage/user.service";
import { SponsorProjectDropdownService } from './shared/components/sponsor-project-dropdown/sponsor-project-dropdown.service';
import { IdleTimeService } from './services/idle-time.service';
import { UserConnectionService } from './services/userconnection.service';
import { StorageService } from './services/storage.service';
import { LoginService } from 'app/services/login.service';
var AppComponent = /** @class */ (function () {
    function AppComponent(element, idle, keepalive, http, loginService, translate, userService, sponsorProjectDropdownService, idleTimeService, userConnectionService, storageService) {
        /**
         * storage event this event trigger each time add or remove or update session & local storage variables.
         * In this code check if Access &Refresh Token is remove then reset the onservable variable null.
         * this function call heppend during when you open the page in multiple tabs in browser. if one of the page
         * logout then other tab trigger this event reset the observable variable.
         */
        var _this = this;
        this.element = element;
        this.idle = idle;
        this.keepalive = keepalive;
        this.http = http;
        this.loginService = loginService;
        this.translate = translate;
        this.userService = userService;
        this.sponsorProjectDropdownService = sponsorProjectDropdownService;
        this.idleTimeService = idleTimeService;
        this.userConnectionService = userConnectionService;
        this.storageService = storageService;
        this.title = 'Session Timeout';
        this.idleState = 'NOT_STARTED';
        this.timedOut = false;
        this.lastPing = null;
        this._jsonURL = 'assets/api/config.json';
        this.languages = [];
        idle.onTimeoutWarning.subscribe(function (countdown) {
            _this.idleState = 'You will time out in ' + countdown + ' seconds!';
        });
        window.addEventListener('storage', function () {
            var tempAccessToken = _this.loginService.getAccessToken();
            var tempRefreshToken = _this.loginService.getRefreshToken();
            if ((!tempAccessToken || !tempRefreshToken) && _this.sponsorProjectDropdownService.checkObservaEmpty()) {
                _this.sponsorProjectDropdownService.resetObservable();
            }
        });
        translate.addLangs(LANGUAGECODES);
        this.languages = LANGUAGES;
        var defaultLanguage = storageService.getItem("language");
        if (defaultLanguage != null) {
            translate.setDefaultLang(this.getCodeFromLanguage(defaultLanguage));
        }
        else {
            translate.setDefaultLang('en');
            storageService.setItem("language", 'English');
        }
        this.userLanguage = 'en';
        LicenseManager.setLicenseKey("CompanyName=Neogenomics Laboratories,LicensedGroup=Pharma Services IT,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=25,LicensedProductionInstancesCount=2,AssetReference=AG-015337,ExpiryDate=26_June_2022_[v2]_MTY1NjE5ODAwMDAwMA==3845996ec59d13d7f4f88d97d23057f7");
        this.idleTimeService.getIdleProperties().subscribe(function (response) {
            idle.setIdle(parseInt(response["setIdle"]));
            _this.TimerTimeOut = parseInt(response["setTimeout"]) * 1000;
            idle.setTimeout(parseInt(response["setTimeout"]));
            idle.setInterrupts([
                new EventTargetInterruptSource(_this.element.nativeElement, 'keydown DOMMouseScroll mousewheel mousedown touchstart touchmove scroll')
            ]);
            idle.onTimeout.subscribe(function () {
                _this.idleState = 'Timed out!';
                _this.timedOut = true;
                _this.idleTimeOutAction();
            });
            idle.onIdleEnd.subscribe(function () {
                _this.idleState = 'NO_LONGER_IDLE';
            });
            idle.onIdleStart.subscribe(function () {
                _this.idleState = 'IDLE_START', _this.showSwal(1);
            });
            keepalive.interval(parseInt(response["setInterval"]));
        });
    }
    AppComponent.prototype.switchLang = function (lang) {
        var _this = this;
        var showDisclaimerText = true;
        var languageCode;
        // If the input is language code it means it has come from backend while login in else the input is language and it has come frrom localstorage
        if (LANGUAGECODES.includes(lang)) {
            showDisclaimerText = false;
            languageCode = lang;
        }
        else {
            languageCode = this.getCodeFromLanguage(lang);
        }
        this.translate.use(languageCode);
        this.storageService.setItem("language", this.getLanguageFromCode(languageCode));
        if (showDisclaimerText) {
            Swal.fire('Disclaimer!', LANGUAGE_DISCLAIMER_TEXT, 'warning');
            var userName = sessionStorage.getItem('loggedUser');
            if (userName != null) {
                this.userService.getUserProfile().subscribe(function (user) {
                    user.locale = _this.getCodeFromLanguage(lang);
                    _this.userService.updateUserProfile(user).subscribe(function (response) {
                    }, function (error) {
                    });
                }, function (error) {
                });
            }
        }
    };
    Object.defineProperty(AppComponent.prototype, "currentLanguage", {
        get: function () {
            return this.translate.currentLang;
        },
        enumerable: true,
        configurable: true
    });
    AppComponent.prototype.ngOnInit = function () {
        var _this = this;
        var token = this.loginService.getAccessToken();
        if (token && token != '' && !this.loginService.getJWTHepler().isTokenExpired()) {
            var decodedToken = this.loginService.initiateAccessToken(token);
            this.userLanguage = decodedToken.locale;
        }
        setInterval(function () {
            if (token) {
                if (_this.loginService.getTokenExpirationtime() <= 30) {
                    _this.loginService.refreshToken().subscribe(function (token) {
                        var accessToken = token[TOKEN_NAME];
                        var refreshToken = token[REFRESH_TOKEN_NAME];
                        _this.loginService.setAccessToken(accessToken);
                        _this.loginService.setRefreshToken(refreshToken);
                        _this.loginService.initiateAccessToken(accessToken);
                        _this.storageService.updateStorageOnOtherTab(new Date());
                    });
                }
            }
        }, 30000);
        this.subscription = this.loginService.loginObservable()
            .subscribe(function (isLoggedIn) {
            if (isLoggedIn) {
                _this.reset();
            }
            else {
                _this.idle.stop();
            }
        });
    };
    AppComponent.prototype.ngOnDestroy = function () {
        this.subscription.unsubscribe();
    };
    AppComponent.prototype.getCodeFromLanguage = function (language) {
        var languageCode = '';
        switch (language) {
            case 'English':
                languageCode = 'en';
                break;
            case 'German':
                languageCode = 'de';
                break;
            case 'Spanish':
                languageCode = 'sp';
                break;
            case 'French':
                languageCode = 'fr';
                break;
            case 'Russian':
                languageCode = 'ru';
                break;
            case 'Chinese':
                languageCode = 'ch';
                break;
            default:
                languageCode = 'en';
        }
        return languageCode;
    };
    AppComponent.prototype.getLanguageFromCode = function (languageCode) {
        var language = '';
        switch (languageCode) {
            case 'en':
                language = 'English';
                break;
            case 'de':
                language = 'German';
                break;
            case 'sp':
                language = 'Spanish';
                break;
            case 'fr':
                language = 'French';
                break;
            case 'ru':
                language = 'Russian';
                break;
            case 'ch':
                language = 'Chinese';
                break;
            default:
                language = 'English';
        }
        return language;
    };
    AppComponent.prototype.getLanguages = function () {
        return this.languages;
    };
    /**
     *
     * @param event This below event trigger each time during refresh event happen either press F5 or click browser reload icon.
     * After check if login user & observable variable not empty then set the value in session storage.
     */
    AppComponent.prototype.unloadHandler = function (event) {
        var isLoggedIn = this.loginService.isLoggedIn();
        if (isLoggedIn && this.sponsorProjectDropdownService.checkObservaEmpty()) {
            this.sponsorProjectDropdownService.returnSponsorDropDownCache().subscribe(function (dropDownData) {
                sessionStorage.setItem("sponsorProjectData", JSON.stringify(dropDownData));
            });
        }
    };
    AppComponent.prototype.setUserPreferences = function (firstName, lastName, language) {
        this.userLanguage = language;
    };
    AppComponent.prototype.showSwal = function (count) {
        var _this = this;
        var timerInterval;
        Swal.fire({
            title: "Session Warning!",
            allowOutsideClick: false,
            html: this.translate.instant("Session Expired Message"),
            timer: this.TimerTimeOut,
            onBeforeOpen: function () {
                timerInterval = setInterval(function () {
                    var content = Swal.getContent();
                    if (content) {
                        var b = content.querySelector('b');
                        if (b) {
                            b.textContent = (Math.round(Swal.getTimerLeft() / 1000)).toString();
                        }
                    }
                }, 1000);
            },
            showConfirmButton: true,
            showCancelButton: true,
            cancelButtonClass: "btn btn-success"
        }).then(function (result) {
            if (result.value) {
                _this.reset();
                _this.loginService.refreshToken().subscribe(function (token) {
                    var accessToken = token[TOKEN_NAME];
                    var refreshToken = token[REFRESH_TOKEN_NAME];
                    _this.loginService.setAccessToken(accessToken);
                    _this.loginService.setRefreshToken(refreshToken);
                    _this.loginService.initiateAccessToken(accessToken);
                    _this.storageService.updateStorageOnOtherTab(new Date());
                }, function (error) {
                    _this.idleTimeOutAction();
                });
            }
            else if (result.dismiss == Swal.DismissReason.cancel) {
                _this.idleTimeOutAction();
            }
        });
    };
    AppComponent.prototype.reverseNumber = function (countdown) {
        return (300 - (countdown - 1));
    };
    AppComponent.prototype.reset = function () {
        this.idle.watch();
        this.idleState = 'Started.';
        this.timedOut = false;
    };
    AppComponent.prototype.idleTimeOutAction = function () {
        var _this = this;
        var connectionId = sessionStorage.getItem('connectionId');
        if (connectionId != null) {
            this.userConnectionService.updateUserConnection(connectionId)
                .toPromise()
                .then(function (res) {
                sessionStorage.removeItem('connectionId');
                _this.loginService.logoutAndDestroyToken();
            }).catch(function (err) {
            });
        }
    };
    return AppComponent;
}());
export { AppComponent };
