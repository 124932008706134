import { NgModule } from '@angular/core';
import {NgIdleKeepaliveModule} from '@ng-idle/keepalive';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { HttpModule } from '@angular/http';
import { APP_BASE_HREF } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppComponent }   from './app.component';
import { SidebarModule } from './sidebar/sidebar.module';
import { FixedPluginModule } from './shared/fixedplugin/fixedplugin.module';
import { FooterModule } from './shared/footer/footer.module';
import { NavbarModule} from './shared/navbar/navbar.module';
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { AppRoutes } from './app.routing';  

import {HttpClient, HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { AgGridModule } from 'ag-grid-angular';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import {UserComponent} from "../app/userpage/user.component";
import { LoggerModule } from 'ngx-logger';
import { environment } from '../../src/environments/environment';
import { ModalModule } from 'ngx-bootstrap';
import { ConfirmLeaveComponent } from './order/confirm-leave/confirm-leave.component';
import { PortalHTTPInterceptor } from './http/interceptors/portalHTTPInterceptor';
import { UserService } from "../app/userpage/user.service";
import { JwtModule } from "@auth0/angular-jwt";
import { DeviceDetectorModule } from 'ngx-device-detector';
import { UserConnectionService } from './services/userconnection.service';

@NgModule({
    imports:      [
        DeviceDetectorModule,
        BrowserAnimationsModule,
        FormsModule,
        RouterModule.forRoot(AppRoutes,{
          useHash: true
        }),
        TranslateModule.forRoot({
            loader: {
              provide: TranslateLoader,
              useFactory: httpTranslateLoader,
              deps: [HttpClient]
            }
          }),
        NgbModule.forRoot(),
        ModalModule.forRoot(),
        NgIdleKeepaliveModule.forRoot(),
        HttpModule,
        SidebarModule,
        NavbarModule,
        FooterModule,
        FixedPluginModule,
        HttpClientModule,
        BrowserModule,
        //importing for logging purposes
        LoggerModule.forRoot({
            serverLoggingUrl: `${environment.apiUrl}/api/logs`, // full path to api end-point for logging to server
            level: environment.logLevel, // TRACE, DEBUG, INFO, LOG, WARN, ERROR, FATAL, OFF - defined in NgxLoggerLevel class.
            serverLogLevel: environment.serverLogLevel, // minimum log level for server-side logging
            disableConsoleLogging: false //  flag to turn console logging completely off
          }),
       
    ],
    declarations: [
        AppComponent,
        AdminLayoutComponent,
        AuthLayoutComponent,
        ConfirmLeaveComponent
    ],
    providers: [
        UserComponent,
        [
            { provide: HTTP_INTERCEPTORS, useClass: PortalHTTPInterceptor, multi: true }
        ],
        UserService,
        UserConnectionService
    ],
    bootstrap:    [ AppComponent ],
    entryComponents: [ConfirmLeaveComponent]
})

export class AppModule {
    public userID: string;

 }

 // AOT compilation support
export function httpTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http,'./assets/i18n/', '.json');
  }