<ng-template
  *ngIf="userType==='sysadmin'; then AdminHomePage;  else (userType === 'datamanager' && DMHomePage) || (DashBoard)">
</ng-template>

<ng-template #AdminHomePage>
    <div class="logo" >
        <a id="manageExternalUserId" routerLink="/systemAdmin/manageExternalUsers">
            <img src="assets/img/NEO_Logo_RGB_2021_new_small.png" />
        </a>
    </div>
</ng-template>

<ng-template #DMHomePage>
    <div class="logo" >
        <a id="" routerLink="/dataManager/manageDTA">
            <img src="assets/img/NEO_Logo_RGB_2021_new_small.png" />
        </a>
    </div>
</ng-template>

<ng-template #DashBoard>
    <div class="logo">
        <a id="mainDashboardId" routerLink="/reports/maindashboard">
            <img src="assets/img/NEO_Logo_RGB_2021_new_small.png"/>
        </a>
    </div>
</ng-template>






    <div sideBarScroll  class="cust-sidebar-style sidebar-wrapper sidebar-collapsing">


    <ul class="nav mt-0 mb-3">
        
        <li [routerLinkActive]="canMark(menuitem)" *ngFor="let menuitem of menuItems; index as i;" >
            <!--If is a single link-->
            <a  class="ml-0"   id="menuLinkId{{i}}" [routerLink]="[menuitem.path]" *ngIf="menuitem.type === 'link'" >
                <i class="{{menuitem.icontype}}"></i>
                <p>{{menuitem.title | translate}}</p>
            </a>
            <!--If it have a submenu-->
            <a  class="ml-0" data-toggle="collapse"  href="#{{menuitem.collapse}}Submenu" role="button" aria-expanded="true" *ngIf="menuitem.type === 'sub'">
                <i class="{{menuitem.icontype}}"></i>
                <p>{{menuitem.title | translate}}<b class="caret"></b></p>
            </a>

            <!--Display the submenu items-->
            <div id="{{menuitem.collapse}}Submenu" class="collapse show collap-style"  *ngIf="menuitem.type === 'sub'">
                <ul class="nav">
  
                    <li routerLinkActive="active" *ngFor="let childitem of menuitem.children; index as j;">
                        <a  class="ml-6" *ngIf="childitem.type=='userGuide'; else elseBlock" id="{{menuitem.collapse}}_ChildId_{{j}}" (click)="getUserGuide()" [routerLink]="[menuitem.path,childitem.path]">
                            <span class="sidebar-mini-icon">{{childitem.ab}}</span>
                            <span class="sidebar-normal" ><b>{{childitem.title | translate}} </b></span>
                        </a>
                        <ng-template #elseBlock>
                            <a   class="ml-6"  id="{{menuitem.collapse}}_ChildId_{{j}}" [routerLink]="menuitem.path.length >0 ?[menuitem.path, childitem.path]: [childitem.path]">
                                <span class="sidebar-mini-icon">{{childitem.ab}}</span>
                                <span class="sidebar-normal" style="white-space: break-spaces;"><b>{{childitem.title | translate}}</b></span>
                            </a>
                        </ng-template>
                    </li>
                </ul>
            </div>
        </li>
    </ul>

</div>