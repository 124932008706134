import { OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
var StorageService = /** @class */ (function () {
    function StorageService(router) {
        this.router = router;
        this.storage = localStorage;
        this.identifier = Math.random().toString() + Date.now().toString();
        Object.keys(sessionStorage).forEach(function (key) {
            localStorage.setItem(key, sessionStorage[key]);
        });
        this.start();
    }
    StorageService.prototype.ngOnDestroy = function () {
        this.stop();
    };
    StorageService.prototype.getItem = function (key, storage) {
        if (storage) {
            return storage.getItem(key);
        }
        return this.storage.getItem(key);
    };
    StorageService.prototype.setItem = function (key, data, storage) {
        if (storage) {
            storage.setItem(key, data);
        }
        this.storage.setItem(key, data);
        sessionStorage.setItem(key, data);
    };
    StorageService.prototype.removeItem = function (key, storage) {
        if (storage) {
            storage.removeItem(key);
        }
        else {
            this.storage.removeItem(key);
        }
        sessionStorage.removeItem(key);
    };
    StorageService.prototype.start = function () {
        var data = {
            id: this.identifier,
            host: window.location.host
        };
        window.addEventListener("storage", this.storageEventListener.bind(this));
        window.addEventListener("beforeunload", this.beforeUnload.bind(this));
    };
    StorageService.prototype.beforeUnload = function () {
        localStorage.setItem("unloading", "true");
        localStorage.clear();
    };
    StorageService.prototype.canProceedTosendStorage = function (jsonStrig) {
        var data = JSON.parse(jsonStrig);
        return (window.location.host == data.host && !(this.identifier == data.id));
    };
    StorageService.prototype.storageEventListener = function (event) {
        if (event.storageArea == localStorage) {
            if (event.key == 'unloading' && event.newValue) {
                Object.keys(sessionStorage).forEach(function (key) {
                    localStorage.setItem(key, sessionStorage[key]);
                });
            }
        }
    };
    StorageService.prototype.updateStorageOnOtherTab = function (newTime) {
        var timeNow = new Date();
        if (newTime) {
            this.storageSetupTime = timeNow;
        }
        var storageData = {
            timeStamp: timeNow,
            data: sessionStorage,
            host: window.location.host
        };
        localStorage.setItem('setSessionStorage', JSON.stringify(storageData));
        localStorage.removeItem('setSessionStorage');
    };
    StorageService.prototype.propagateNEwValue = function (key, value) {
        var data = {
            key: key,
            value: value,
            host: window.location.host
        };
        localStorage.setItem('updateValue', JSON.stringify(data));
        localStorage.removeItem('updateValue');
    };
    StorageService.prototype.setNewValue = function (jsonString) {
        var data = JSON.parse(jsonString);
        if (window.location.host == data.host) {
            this.storage.setItem(data.key, data.value);
        }
    };
    StorageService.prototype.stop = function () {
        window.removeEventListener("storage", this.storageEventListener.bind(this));
    };
    StorageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function StorageService_Factory() { return new StorageService(i0.ɵɵinject(i1.Router)); }, token: StorageService, providedIn: "root" });
    return StorageService;
}());
export { StorageService };
