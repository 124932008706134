import { AfterContentInit, AfterViewInit, Component, Directive, ElementRef, HostListener, OnInit, Renderer2, ViewChild } from '@angular/core';
import { LoginComponent } from '../pages/login/login.component'
import { LoginService } from 'app/services/login.service';
import { Router } from '@angular/router';
import { SIDEBARHEADING } from '../shared/pharmaportal.constant';
export interface TopNavItems {
    path?: String,
    title?: string;
    description?: string
}

export const TOPNAVDROPDOWN: TopNavItems[] = [{
    path: '/users/user',
    title: SIDEBARHEADING.EDIT_PROFILE,
    description: ''
},
{
    path: '/forms/validation',
    title: SIDEBARHEADING.CHANGE_PASSWORD,
    description: ''
}];
//Metadata
export interface RouteInfo {
    path: string;
    title: string;
    type: string;
    collapse?: string;
    icontype: string;
    authorities?: string[];
    restrict?: string[];
    children?: ChildrenItems[];
    discription?: string;
}

export interface ChildrenItems {
    path: string;
    title: string;
    ab: string;
    type?: string;
    local?: string;
    sponsor?: boolean;
    authorities?: string[];
    restrict?: string[];
    discription?: string;
}

//Menu Items
export const ROUTES: RouteInfo[] = [
    {
        path: '/reports/maindashboard',
        title: SIDEBARHEADING.HOME_PAGE,
        type: 'link',
        collapse: 'reports',
        icontype: 'nc-icon nc-circle-10',
        authorities: ['ROLE_NEOPM', 'ROLE_SPONSOR', 'ROLE_INVESTIGATOR', 'ROLE_INVPM', 'ROLE_INVSPONSOR', 'ROLE_INVINVESTIGATOR']
    },

    {
        path: '/reports/worklist',
        title: SIDEBARHEADING.WORKLIST,
        type: 'link',
        icontype: 'nc-icon nc-single-copy-04',
        authorities: ['ROLE_NEOPM', 'ROLE_SPONSOR', 'ROLE_INVESTIGATOR','ROLE_INVPM','ROLE_INVSPONSOR','ROLE_INVINVESTIGATOR' ],
        discription: '- List of tests and associated reports (Excluding Backups)'
    },

   
    {
        path: '/reports/inventory',
        title: SIDEBARHEADING.INVENTORY_LIST,
        type: 'link',
        collapse: 'Inventory List',
        icontype: 'nc-icon nc-paper',
        authorities: ['ROLE_NEOPM', 'ROLE_SPONSOR', 'ROLE_INVESTIGATOR', 'ROLE_INVPM','ROLE_INVSPONSOR','ROLE_INVINVESTIGATOR'],
        discription: '- List of samples stored at Neogenomics'
    },

    {
        path: '/data/dataexport',
        title: SIDEBARHEADING.DATA_EXPORT,
        type: 'link',
        collapse: 'Data Export',
        icontype: 'nc-icon nc-cloud-download-93',
        authorities: ['ROLE_NEOPM', 'ROLE_SPONSOR', 'ROLE_INVESTIGATOR']
    },
    {
        path: '/data/bulkdownload',
        title: SIDEBARHEADING.BULK_DOWNLOAD,
        type: 'link',
        collapse: 'Bulk Download',
        icontype: 'nc-icon nc-cloud-download-93',
        authorities: ['ROLE_NEOPM', 'ROLE_SPONSOR', 'ROLE_INVESTIGATOR']
    },

    {
        path: '/pages/manageuser',
        title: SIDEBARHEADING.MANAGE_USERS,
        collapse: 'pages',
        type: 'sub',
        authorities: ['ROLE_NEOPM', 'ROLE_SPONSOR', 'ROLE_INVPM'],
        icontype: 'nc-icon nc-book-bookmark',
        children: [

            { path: 'manageSponsor', title: SIDEBARHEADING.MANAGE_SPONSOR, ab: 'MS', },
            { path: 'manageInvestigator', title: SIDEBARHEADING.MANAGE_INVESTIGATOR, ab: 'MI' },
        ],
        restrict: ['ROLE_DATAMANAGER']
    },
    {
        path: '',
        title: SIDEBARHEADING.ONLINE_ORDERING,
        collapse: 'onlineorder',
        type: 'sub',
        authorities: ['ROLE_NEOPM', 'ROLE_SPONSOR', 'ROLE_INVESTIGATOR','ROLE_INVPM','ROLE_INVSPONSOR','ROLE_INVINVESTIGATOR'],
        icontype: 'nc-icon nc-atom',
        children: [
            { path: '/order', title: SIDEBARHEADING.CREATE_NEW_ORDER_GENERIC, ab: 'OR', authorities: ['ROLE_NEOPM', 'ROLE_SPONSOR', 'ROLE_INVESTIGATOR','ROLE_INVPM','ROLE_INVSPONSOR','ROLE_INVINVESTIGATOR'] },
            { path: '/invorder', title: SIDEBARHEADING.CREATE_NEW_ORDER_MOL_ASSAY, ab: 'OR', authorities: ['ROLE_INVPM','ROLE_INVSPONSOR','ROLE_INVINVESTIGATOR','ROLE_NEOPM', 'ROLE_SPONSOR', 'ROLE_INVESTIGATOR'] },
            { path: '/reports/orderlist', title: SIDEBARHEADING.ORDER_LIST, ab: 'OL', discription: 'View, Modify, Cancel Online Order', authorities: ['ROLE_NEOPM', 'ROLE_SPONSOR', 'ROLE_INVESTIGATOR','ROLE_INVPM','ROLE_INVSPONSOR','ROLE_INVINVESTIGATOR'] }
        ]
    },
    {
        path: '/search/globalSearch',
        title: SIDEBARHEADING.GLOBAL_SEARCH ,
        type: 'link',
        icontype: 'nc-icon nc-zoom-split',
        authorities: ['ROLE_NEOPM', 'ROLE_SPONSOR', 'ROLE_INVESTIGATOR', 'ROLE_INVPM', 'ROLE_INVSPONSOR', 'ROLE_INVINVESTIGATOR'],
        collapse : 'globalsearch',
        discription: '- Search By Accession ID / Order ID'
  
    },
    {
        path: '/login/loginAs',
        title: "Sign In With Different Role",
        icontype: '',
        type: 'role',
        authorities: [ "ROLE_NEOPM", "ROLE_INVPM"],
    },
    {
        path: '/systemAdmin',
        title: SIDEBARHEADING.SYSTEM_ADMIN,
        collapse: 'sysadmin',
        type: 'sub',
        icontype: 'nc-icon nc-settings-gear-65',
        authorities: ['ROLE_SYSADMIN'],
        children: [
            { path: 'manageExternalUsers', title: SIDEBARHEADING.MANAGE_EXTERNAL_USERS, ab: 'EU', authorities: ['ROLE_SYSADMIN'] },
            { path: 'manualSync', title: SIDEBARHEADING.MANUAL_SYNC, ab: 'MS', authorities: ['ROLE_NEOPM'] },
            { path: 'environmentalProperties', title: SIDEBARHEADING.ENVIRONMENTAL_PROPERTIES, ab: 'EP', authorities: ['ROLE_SYSADMIN'] },
            { path: 'userConnections', title: SIDEBARHEADING.USER_CONNECTIONS, ab: 'UC', authorities: ['ROLE_SYSADMIN'] },
            { path: 'deploymentNotification', title: SIDEBARHEADING.DEPLOYMENT_NOTIFICATION,discription: SIDEBARHEADING.DEPLOYMENT_NOTIFICATION_DESC, ab: 'DN', authorities: ['ROLE_SYSADMIN'] }
                 
        ]
    },
    {
        path: '/systemAdmin/VendorAdministration',
        title: SIDEBARHEADING.VENDOR_ADMINISTRATION,
        collapse: 'sysadmin',
        type: 'sub',
        icontype: 'nc-icon nc-settings-gear-65',
        authorities: ['ROLE_SYSADMIN'],
        children: [
            { path: 'manageServiceAccountUsers', title: SIDEBARHEADING.MANAGE_SERVICE_ACCOUNT_USERS, ab: 'ME', authorities: ['ROLE_SYSADMIN'] },
            { path: 'sftpConnectionSetup', title: SIDEBARHEADING.SFTP_REPORT_CON_SET, discription: SIDEBARHEADING.SFTP_REPORT_CON_DESC, ab: 'SF', authorities: ['ROLE_SYSADMIN'] },
            { path: 'sftpDetails', title: SIDEBARHEADING.SFTP_DETAILS, discription: SIDEBARHEADING.SFTP_DETAILS_DESC, ab: 'SD', authorities: ['ROLE_SYSADMIN'] }

        ]
    }, 
    {
        path: '/dataManager',
        title: SIDEBARHEADING.DATA_MANAGER,
        collapse: 'dm',
        type: 'sub',
        icontype: 'nc-icon nc-tile-56',
        authorities: ['ROLE_DATAMANAGER', 'ROLE_NEOPM', 'ROLE_SPONSOR', 'ROLE_INVPM', 'ROLE_INVSPONSOR'],
        children: [
            { path: 'manageDTA', title: SIDEBARHEADING.MANAGE_DTA, ab: 'DFU',discription: SIDEBARHEADING.DTA_FILE_UPLOAD_DESC, authorities: ['ROLE_DATAMANAGER','ROLE_NEOPM', 'ROLE_INVPM'] },
            { path: 'dtaFileDownload', title: SIDEBARHEADING.DTA_FILE_DOWNLOAD, discription: SIDEBARHEADING.DTA_FILE_DOWNLOAD_DESC, ab: 'DFD', authorities: ['ROLE_DATAMANAGER', 'ROLE_NEOPM', 'ROLE_SPONSOR', 'ROLE_INVPM', 'ROLE_INVSPONSOR'] }
               
        ]
    },
    {
        path: '/project/projectAccessSetup',
        title: SIDEBARHEADING.PROJECT_SETUP,
        type: 'link',
        icontype: 'nc-icon nc-settings-gear-65',
        authorities: ['ROLE_NEOPM', 'ROLE_INVPM'],
        restrict: ['ROLE_DATAMANAGER']
    },
    {
        path: '/sftpinventory/inventorycustcol',
        title: SIDEBARHEADING.INV_COLUMN_CUSTOMIZE ,
        type: 'link',
        icontype: 'nc-icon nc-settings-gear-65',
        authorities: ['ROLE_NEOPM'],
        restrict: ['ROLE_DATAMANAGER']
    },
    {
        path: '/sftpinventory/dataexportcustcol',
        title: SIDEBARHEADING.DATAEXPORT_COLUMN_CUSTOMIZE ,
        type: 'link',
        icontype: 'nc-icon nc-settings-gear-65',
        authorities: ['ROLE_NEOPM'],
        restrict: ['ROLE_DATAMANAGER']
    },
    {
        path: '',
        title: SIDEBARHEADING.AUDIT_REPORT ,
        type: 'sub',
        icontype: 'nc-icon nc-single-copy-04',
        authorities: ['ROLE_NEOPM','ROLE_SYSADMIN','ROLE_INVPM'],
        collapse : 'audit',
        children :[
            { path: '/systemAdmin/VendorAdministration/sftpDetails', title: SIDEBARHEADING.SFTP_DETAILS, discription: SIDEBARHEADING.SFTP_DETAILS_DESC, ab: 'SD', authorities: ['ROLE_NEOPM', 'ROLE_INVPM'] },
            { path: '/reports/audit-report', title: SIDEBARHEADING.ORDER_AUDIT_REPORT, ab: 'AR', authorities: ['ROLE_NEOPM'] },
            { path: '/reports/data-transfer-files-download-report', title: SIDEBARHEADING.DTA_FILE_DOWNLOAD_REPORT,discription: SIDEBARHEADING.DTA_FILE_DOWNLOAD_REPORT_DESC, ab: 'DR', authorities: ['ROLE_NEOPM'] },
            { path: '/reports/report-status', title: SIDEBARHEADING.REPORT_STATUS,discription: SIDEBARHEADING.REPORT_STATUS_DESC, ab: 'RS', authorities: ['ROLE_NEOPM'] },
            { path: '/reports/sftp-data-transfer-status', title: SIDEBARHEADING.SFTP_DATA_TRANSFER_STATUS,discription: SIDEBARHEADING.SFTP_DATA_TRANSFER_STATUS_DESC, ab: 'STR', authorities: ['ROLE_NEOPM', 'ROLE_INVPM'] },
            { path: '/reports/sftp-inventory-report-status', title: SIDEBARHEADING.SFTP_INVENTORY_REPORT_DETAILS,discription: SIDEBARHEADING.SFTP_INVENTORY_REPORT_DETAILS_DESC, ab: 'IRS', authorities: ['ROLE_NEOPM'] },
            { path: '/reports/sftp-dataexport-report-status', title: SIDEBARHEADING.SFTP_DATAEXPORT_REPORT_DETAILS,discription: SIDEBARHEADING.SFTP_DATAEXPORT_REPORT_DETAILS_DESC, ab: 'IRS', authorities: ['ROLE_NEOPM'] },
            { path: '/reports/sponsorcount', title: SIDEBARHEADING.SPONSOR_COUNT,discription: SIDEBARHEADING.SPONSOR_COUNT_DESC, ab: 'SC', authorities: ['ROLE_NEOPM','ROLE_SYSADMIN', 'ROLE_INVPM'] },
            { path: '/reports/projectcount', title: SIDEBARHEADING.PROJECT_COUNT,discription: SIDEBARHEADING.PROJECT_COUNT_DESC, ab: 'PC', authorities: ['ROLE_NEOPM','ROLE_SYSADMIN', 'ROLE_INVPM'] },
            { path: '/reports/usercount', title: SIDEBARHEADING.USER_COUNT,discription: SIDEBARHEADING.USER_COUNT_DESC, ab: 'UC', authorities: ['ROLE_NEOPM','ROLE_SYSADMIN', 'ROLE_INVPM'] },
        ]
    },  
    {
        path: '',
        title: SIDEBARHEADING.HELP,
        type: 'sub',
        collapse: 'Help',
        icontype: 'nc-icon nc-alert-circle-i',
        authorities: ['ROLE_NEOPM', 'ROLE_SPONSOR', 'ROLE_INVESTIGATOR','ROLE_INVPM', 'ROLE_INVSPONSOR', 'ROLE_INVINVESTIGATOR'],


        children: [
            { path: 'userGuide', title: SIDEBARHEADING.USER_GUIDE, ab: 'UG', type: 'userGuide' }
        ]
    },
    {
        path: '',
        title: SIDEBARHEADING.HELP,
        type: 'sub',
        collapse: 'Help',
        icontype: 'nc-icon nc-alert-circle-i',
        authorities: ['ROLE_SYSADMIN'],


        children: [
            { path: 'userGuideSysAdmin', title: SIDEBARHEADING.USER_GUIDE, ab: 'UG', type: 'userGuide' }
        ]
    }

   
];

@Component({
    moduleId: module.id,
    selector: 'sidebar-cmp',
    templateUrl: 'sidebar.component.html',
})

export class SidebarComponent implements OnInit {
    public menuItems: any[];
    public login: LoginComponent;
    public userType: string;
    public constRoute: any[]=[];
    userDisplayName = sessionStorage.getItem('loggedUser');
    constructor(private loginService: LoginService, private router: Router) {
        this.userType = sessionStorage.getItem('loggedUserType');
        //Due to Shallow Object copy issue convert to json.
        this.constRoute = JSON.parse(JSON.stringify(ROUTES)) ;
    }
    isNotMobileMenu() {
        if (window.outerWidth > 991) {
            return false;
        }
        return true;
    }


    ngOnInit() {
        let decodedToken = this.loginService.getJWTHepler().decodeToken(this.loginService.getAccessToken());
        let authorities = decodedToken.authorities;
        this.menuItems = this.constRoute.filter(menuItem => {
            if (!menuItem.authorities) {
                return menuItem;
            }
            else {
                let isAllowed = false;
                let isRestrict = false;
                menuItem.authorities.forEach(auth => {
                    if (!isAllowed) {
                        isAllowed = authorities.includes(auth);
                    }
                });
                // Hide menu item for restricted user roles
                if(menuItem.restrict){
                    menuItem.restrict.forEach(auth => {
                        if(!isRestrict){
                            isRestrict = authorities.includes(auth);
                        }
                    });
                }
                isAllowed = isRestrict ? false : isAllowed;
                if (isAllowed) {
                    return menuItem;
                }
            }
        });
        this.menuItems.filter(menuItem => menuItem.children).forEach(menuItem => {
            menuItem.children = menuItem.children.filter(childItem => {
                if (!childItem.authorities) {
                    return childItem;
                }
                else {
                    let isAllowed = false;
                    childItem.authorities.forEach(auth => {
                        if (!isAllowed) {
                            isAllowed = authorities.includes(auth);
                        }
                    });
                    if (isAllowed) {
                        return childItem;
                    }
                }
            });
        })
        this.userDisplayName = sessionStorage.getItem('loggedUser');
    }

    getUserGuide() {
        this.loginService.getUserGuide().subscribe((response) => {  
            const file = new Blob([response], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            // To check the user is usign IE 11 brwoser for the file download
            if (window.navigator.msSaveOrOpenBlob) {
                // To download a file in IE browser
                window.navigator.msSaveOrOpenBlob(file, "UserGuide.pdf");
            } else {
                window.open(fileURL);
            }

        });
    }
    canMark(menuItem: RouteInfo) {
        if (menuItem.title == 'Help') {
            return "false";
        }   
        return "active";
    }
}



