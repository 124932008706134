import * as i0 from "@angular/core";
var DataService = /** @class */ (function () {
    function DataService() {
        this.previousData = '';
        this.currentdata = null;
        this.orderId = '';
        this.islogout = false;
        this.orderLockFlag = 'N';
    }
    DataService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DataService_Factory() { return new DataService(); }, token: DataService, providedIn: "root" });
    return DataService;
}());
export { DataService };
