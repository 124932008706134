/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common";
import * as i3 from "@ngx-translate/core";
import * as i4 from "./sidebarScroll.directive";
import * as i5 from "./sidebar.component";
import * as i6 from "../services/login.service";
var styles_SidebarComponent = [];
var RenderType_SidebarComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SidebarComponent, data: {} });
export { RenderType_SidebarComponent as RenderType_SidebarComponent };
function View_SidebarComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(0, null, null, 0))], null, null); }
function View_SidebarComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(0, null, null, 0, null, View_SidebarComponent_2))], null, null); }
function View_SidebarComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "logo"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "a", [["id", "manageExternalUserId"], ["routerLink", "/systemAdmin/manageExternalUsers"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 0, "img", [["src", "assets/img/NEO_Logo_RGB_2021_new_small.png"]], null, null, null, null, null))], function (_ck, _v) { var currVal_2 = "/systemAdmin/manageExternalUsers"; _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 2).target; var currVal_1 = i0.ɵnov(_v, 2).href; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_SidebarComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "logo"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "a", [["id", ""], ["routerLink", "/dataManager/manageDTA"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 0, "img", [["src", "assets/img/NEO_Logo_RGB_2021_new_small.png"]], null, null, null, null, null))], function (_ck, _v) { var currVal_2 = "/dataManager/manageDTA"; _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 2).target; var currVal_1 = i0.ɵnov(_v, 2).href; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_SidebarComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "logo"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "a", [["id", "mainDashboardId"], ["routerLink", "/reports/maindashboard"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 0, "img", [["src", "assets/img/NEO_Logo_RGB_2021_new_small.png"]], null, null, null, null, null))], function (_ck, _v) { var currVal_2 = "/reports/maindashboard"; _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 2).target; var currVal_1 = i0.ɵnov(_v, 2).href; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_SidebarComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "a", [["class", "ml-0"]], [[8, "id", 0], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 671744, [[2, 4]], 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i0.ɵpad(2, 1), (_l()(), i0.ɵeld(3, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, ["", ""])), i0.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var currVal_3 = _ck(_v, 2, 0, _v.parent.context.$implicit.path); _ck(_v, 1, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i0.ɵinlineInterpolate(1, "menuLinkId", _v.parent.context.index, ""); var currVal_1 = i0.ɵnov(_v, 1).target; var currVal_2 = i0.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_4 = i0.ɵinlineInterpolate(1, "", _v.parent.context.$implicit.icontype, ""); _ck(_v, 3, 0, currVal_4); var currVal_5 = i0.ɵunv(_v, 5, 0, i0.ɵnov(_v, 6).transform(_v.parent.context.$implicit.title)); _ck(_v, 5, 0, currVal_5); }); }
function View_SidebarComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "a", [["aria-expanded", "true"], ["class", "ml-0"], ["data-toggle", "collapse"], ["role", "button"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 3, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""])), i0.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(5, 0, null, null, 0, "b", [["class", "caret"]], null, null, null, null, null))], null, function (_ck, _v) { var currVal_0 = i0.ɵinlineInterpolate(1, "#", _v.parent.context.$implicit.collapse, "Submenu"); _ck(_v, 0, 0, currVal_0); var currVal_1 = i0.ɵinlineInterpolate(1, "", _v.parent.context.$implicit.icontype, ""); _ck(_v, 1, 0, currVal_1); var currVal_2 = i0.ɵunv(_v, 3, 0, i0.ɵnov(_v, 4).transform(_v.parent.context.$implicit.title)); _ck(_v, 3, 0, currVal_2); }); }
function View_SidebarComponent_11(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "a", [["class", "ml-6"]], [[8, "id", 0], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.getUserGuide() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 671744, [[4, 4], [2, 4]], 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i0.ɵpad(2, 2), (_l()(), i0.ɵeld(3, 0, null, null, 1, "span", [["class", "sidebar-mini-icon"]], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", ""])), (_l()(), i0.ɵeld(5, 0, null, null, 3, "span", [["class", "sidebar-normal"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 2, "b", [], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, ["", " "])), i0.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var currVal_3 = _ck(_v, 2, 0, _v.parent.parent.parent.context.$implicit.path, _v.parent.context.$implicit.path); _ck(_v, 1, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i0.ɵinlineInterpolate(2, "", _v.parent.parent.parent.context.$implicit.collapse, "_ChildId_", _v.parent.context.index, ""); var currVal_1 = i0.ɵnov(_v, 1).target; var currVal_2 = i0.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_4 = _v.parent.context.$implicit.ab; _ck(_v, 4, 0, currVal_4); var currVal_5 = i0.ɵunv(_v, 7, 0, i0.ɵnov(_v, 8).transform(_v.parent.context.$implicit.title)); _ck(_v, 7, 0, currVal_5); }); }
function View_SidebarComponent_12(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "a", [["class", "ml-6"]], [[8, "id", 0], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 671744, [[4, 4], [2, 4]], 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i0.ɵpad(2, 2), i0.ɵpad(3, 1), (_l()(), i0.ɵeld(4, 0, null, null, 1, "span", [["class", "sidebar-mini-icon"]], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, ["", ""])), (_l()(), i0.ɵeld(6, 0, null, null, 3, "span", [["class", "sidebar-normal"], ["style", "white-space: break-spaces;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 2, "b", [], null, null, null, null, null)), (_l()(), i0.ɵted(8, null, ["", ""])), i0.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var currVal_3 = ((_v.parent.parent.parent.context.$implicit.path.length > 0) ? _ck(_v, 2, 0, _v.parent.parent.parent.context.$implicit.path, _v.parent.context.$implicit.path) : _ck(_v, 3, 0, _v.parent.context.$implicit.path)); _ck(_v, 1, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i0.ɵinlineInterpolate(2, "", _v.parent.parent.parent.context.$implicit.collapse, "_ChildId_", _v.parent.context.index, ""); var currVal_1 = i0.ɵnov(_v, 1).target; var currVal_2 = i0.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_4 = _v.parent.context.$implicit.ab; _ck(_v, 5, 0, currVal_4); var currVal_5 = i0.ɵunv(_v, 8, 0, i0.ɵnov(_v, 9).transform(_v.parent.context.$implicit.title)); _ck(_v, 8, 0, currVal_5); }); }
function View_SidebarComponent_10(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "li", [["routerLinkActive", "active"]], null, null, null, null, null)), i0.ɵdid(1, 1720320, null, 2, i1.RouterLinkActive, [i1.Router, i0.ElementRef, i0.Renderer2, [2, i1.RouterLink], [2, i1.RouterLinkWithHref]], { routerLinkActive: [0, "routerLinkActive"] }, null), i0.ɵqud(603979776, 3, { links: 1 }), i0.ɵqud(603979776, 4, { linksWithHrefs: 1 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SidebarComponent_11)), i0.ɵdid(5, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵand(0, [["elseBlock", 2]], null, 0, null, View_SidebarComponent_12))], function (_ck, _v) { var currVal_0 = "active"; _ck(_v, 1, 0, currVal_0); var currVal_1 = (_v.context.$implicit.type == "userGuide"); var currVal_2 = i0.ɵnov(_v, 6); _ck(_v, 5, 0, currVal_1, currVal_2); }, null); }
function View_SidebarComponent_9(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "collapse show collap-style"]], [[8, "id", 0]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "ul", [["class", "nav"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SidebarComponent_10)), i0.ɵdid(3, 278528, null, 0, i2.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_1 = _v.parent.context.$implicit.children; _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i0.ɵinlineInterpolate(1, "", _v.parent.context.$implicit.collapse, "Submenu"); _ck(_v, 0, 0, currVal_0); }); }
function View_SidebarComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "li", [], null, null, null, null, null)), i0.ɵdid(1, 1720320, null, 2, i1.RouterLinkActive, [i1.Router, i0.ElementRef, i0.Renderer2, [2, i1.RouterLink], [2, i1.RouterLinkWithHref]], { routerLinkActive: [0, "routerLinkActive"] }, null), i0.ɵqud(603979776, 1, { links: 1 }), i0.ɵqud(603979776, 2, { linksWithHrefs: 1 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SidebarComponent_7)), i0.ɵdid(5, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SidebarComponent_8)), i0.ɵdid(7, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SidebarComponent_9)), i0.ɵdid(9, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.canMark(_v.context.$implicit); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_v.context.$implicit.type === "link"); _ck(_v, 5, 0, currVal_1); var currVal_2 = (_v.context.$implicit.type === "sub"); _ck(_v, 7, 0, currVal_2); var currVal_3 = (_v.context.$implicit.type === "sub"); _ck(_v, 9, 0, currVal_3); }, null); }
export function View_SidebarComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_SidebarComponent_1)), i0.ɵdid(1, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfThen: [1, "ngIfThen"], ngIfElse: [2, "ngIfElse"] }, null), (_l()(), i0.ɵand(0, [["AdminHomePage", 2]], null, 0, null, View_SidebarComponent_3)), (_l()(), i0.ɵand(0, [["DMHomePage", 2]], null, 0, null, View_SidebarComponent_4)), (_l()(), i0.ɵand(0, [["DashBoard", 2]], null, 0, null, View_SidebarComponent_5)), (_l()(), i0.ɵeld(5, 0, null, null, 4, "div", [["class", "cust-sidebar-style sidebar-wrapper sidebar-collapsing"], ["sideBarScroll", ""]], null, [[null, "scroll"]], function (_v, en, $event) { var ad = true; if (("scroll" === en)) {
        var pd_0 = (i0.ɵnov(_v, 6).onScroll() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(6, 81920, null, 0, i4.sideBarScrollDirective, [i0.ElementRef, i1.Router], null, null), (_l()(), i0.ɵeld(7, 0, null, null, 2, "ul", [["class", "nav mt-0 mb-3"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SidebarComponent_6)), i0.ɵdid(9, 278528, null, 0, i2.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.userType === "sysadmin"); var currVal_1 = i0.ɵnov(_v, 2); var currVal_2 = (((_co.userType === "datamanager") && i0.ɵnov(_v, 3)) || i0.ɵnov(_v, 4)); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); _ck(_v, 6, 0); var currVal_3 = _co.menuItems; _ck(_v, 9, 0, currVal_3); }, null); }
export function View_SidebarComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "sidebar-cmp", [], null, null, null, View_SidebarComponent_0, RenderType_SidebarComponent)), i0.ɵdid(1, 114688, null, 0, i5.SidebarComponent, [i6.LoginService, i1.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SidebarComponentNgFactory = i0.ɵccf("sidebar-cmp", i5.SidebarComponent, View_SidebarComponent_Host_0, {}, {}, []);
export { SidebarComponentNgFactory as SidebarComponentNgFactory };
