import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Observable, of} from 'rxjs';
import { shareReplay , catchError, map} from 'rxjs/operators';
import {ErrorHandlerService } from '../../../services/error-handler.service';
const CACHE_SIZE = 1;
@Injectable({
  providedIn: 'root'
})

export class SponsorProjectDropdownService {
  
  sponsorProjectDropdownData: Observable<any>;
  private cache$: Observable<any>;

  constructor(private http:HttpClient, private errorHandlerService: ErrorHandlerService) {
   }

  getSponsorProjectDropdownData(selectVendorSponsor: boolean){
    let urlData = "";
    if(selectVendorSponsor){
      urlData = environment.apiUrl + "/api/sponsor/getSponsorProjectDropdownData?selectVendorSponsor="+selectVendorSponsor;
    } else {
      urlData = environment.apiUrl + "/api/sponsor/getSponsorProjectDropdownData";
    }
   
      this.sponsorProjectDropdownData = this.http.post(urlData, null);

    return this.sponsorProjectDropdownData;
  }

  getSelectedSponsorProjectData(): Observable<any>{

    let urlData =  environment.apiUrl + "/api/sponsor/getSelectedSponsorProjectData";
    
    return this.http.get<any>(urlData).pipe(
      catchError(this.errorHandlerService.errorHandler)
      );  
  }
  
  resetObservable(){
    this.cache$ = null;
  }


  // Here check observerable undefined then load the data from server otherwise get from Observable variable. 
  /**
   * During refresh the browser data clear in Observable variable that time save the dropdown sponsor Project detail in session storage 
   * once after referesh recall the service get the data from session storage load in to observable variable remove the session variable.
   */
  get getSponsorProjectDetail() {
    let sessionSpnsorProjectData : any = sessionStorage.getItem("sponsorProjectData");
    if (!this.cache$ && (!sessionSpnsorProjectData || sessionSpnsorProjectData == null || sessionSpnsorProjectData == "undefined")) {
      this.cache$ = this.requestSponsorProjectDetail().pipe(
        shareReplay(CACHE_SIZE)
      );
     }else if(!this.cache$ && sessionSpnsorProjectData) {

      this.cache$ = of(JSON.parse(sessionSpnsorProjectData)).pipe(
        shareReplay(CACHE_SIZE)
      );
      sessionStorage.removeItem("sponsorProjectData");
    }

    return this.cache$;
  }
    /**
     * Here this service call every page get the data from  "this.cache$" varibale check if it null load the data from database 
     * otherwise check if data empty retrun false otherwise true depends on condition hide or show the page. 
     */
   get getSponsorProjectDataStatus() {
      let sessionSpnsorProjectData : any = sessionStorage.getItem("sponsorProjectData");
      if (!this.cache$ && (!sessionSpnsorProjectData || sessionSpnsorProjectData == null || sessionSpnsorProjectData == "undefined")) {
        this.cache$ = this.requestSponsorProjectDetail().pipe(
          shareReplay(CACHE_SIZE)
        );
       }else if(!this.cache$ && sessionSpnsorProjectData) {
  
        this.cache$ = of(JSON.parse(sessionSpnsorProjectData)).pipe(
          shareReplay(CACHE_SIZE)
        );
        sessionStorage.removeItem("sponsorProjectData");
      }
    return this.cache$.pipe(map(data=>{
    return this.checkSponsorDataEmptyOrNot(data);
    }));
    }

  private requestSponsorProjectDetail(){
    return this.http.post(environment.apiUrl+'/api/sponsor/getSponsorProjectDropdownData', null);
  }

  returnSponsorDropDownCache(){
      return this.cache$;
  }

  checkObservaEmpty(){
    return this.cache$ ? true : false;
  }

  //this part of code check if data empty or not depends return true or false.
  checkSponsorDataEmptyOrNot(data: any){
    let tempSponsorProjectStatus = true;
    if(data){

      let tempSponsorValue = (<any>data).sponsors;

      if(tempSponsorValue.length >0){
        tempSponsorProjectStatus = tempSponsorValue[0].id ? true : false;
      }else {
        tempSponsorProjectStatus = false;
      }
    }else {

      tempSponsorProjectStatus = false;
    }

    return tempSponsorProjectStatus
  }

  getSponsorProjectDataByReportId(reportId: string){
    let urlData = environment.apiUrl + "/api/data/getDataExportReqSpon";
    return this.http.get<any>(urlData, {
      params: {
        reqReportId: reportId,
      }
    })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          return this.errorHandlerService.errorHandlerWithTranslator(error);
        })
      );
  }

  getSponsorProjectDataByReportId_BulkDownload(reportId: string){
    let urlData = environment.apiUrl + "/api/bulkDownload/getSponsorProjectData";
    return this.http.get<any>(urlData, {
      params: {
        reqReportId: reportId,
      }
    })
    .pipe(
      catchError((error: HttpErrorResponse) => {
        return this.errorHandlerService.errorHandlerWithTranslator(error);
      })
    );
  }
}
