import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var IdleTimeService = /** @class */ (function () {
    function IdleTimeService(http) {
        this.http = http;
    }
    IdleTimeService.prototype.getIdleProperties = function () {
        return this.http.get(environment.apiUrl + '/api/security/getIdleTimeProperties');
    };
    IdleTimeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function IdleTimeService_Factory() { return new IdleTimeService(i0.ɵɵinject(i1.HttpClient)); }, token: IdleTimeService, providedIn: "root" });
    return IdleTimeService;
}());
export { IdleTimeService };
