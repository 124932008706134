import { Keepalive } from '@ng-idle/keepalive';
import { EventTargetInterruptSource, Idle } from '@ng-idle/core';
import { Component, ElementRef, Injectable, OnDestroy, HostListener } from '@angular/core';
import Swal from 'sweetalert2';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { LicenseManager } from "ag-grid-enterprise";
import { DecodedToken } from './services/tokenModel';
import { TOKEN_NAME, REFRESH_TOKEN_NAME } from './services/auth.constant';
import { TranslateService } from '@ngx-translate/core';
import { LANGUAGES } from './shared/pharmaportal.constant';
import { LANGUAGECODES } from './shared/pharmaportal.constant';
import { LANGUAGE_DISCLAIMER_TEXT } from './shared/pharmaportal.constant';
import { UserService } from "../app/userpage/user.service";
import { SponsorProjectDropdownService } from  './shared/components/sponsor-project-dropdown/sponsor-project-dropdown.service';
import { IdleTimeService } from './services/idle-time.service';
import { UserConnectionService } from './services/userconnection.service';
import { StorageService } from './services/storage.service';
import { LoginService } from 'app/services/login.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

@Injectable()
export class AppComponent implements OnDestroy{

  userLoginId: string;
  userLanguage: string;
  TimerTimeOut : number;
  title = 'Session Timeout';
  idleState = 'NOT_STARTED';
  timedOut = false;
  lastPing?: Date = null;
  val?: string;
  _jsonURL = 'assets/api/config.json';
  languages = [];
  selectedSponsorId: number;
  selectedSponsorName: String;
  selectedSponsorDescription: String;
  selectedProjectId: number;
  selectedProjectName: String;

  public rowData;
  public switchLang(lang: string) {
    
    let showDisclaimerText = true;
    let languageCode;
	
    // If the input is language code it means it has come from backend while login in else the input is language and it has come frrom localstorage
    if(LANGUAGECODES.includes(lang)){
      showDisclaimerText = false;
      languageCode = lang;
    } else {
      languageCode = this.getCodeFromLanguage(lang)
    }


    this.translate.use(languageCode);
    this.storageService.setItem("language",this.getLanguageFromCode(languageCode));
    if(showDisclaimerText) {
        
        Swal.fire(
          'Disclaimer!',
          LANGUAGE_DISCLAIMER_TEXT,
          'warning'
        );

        let userName = sessionStorage.getItem('loggedUser');
        if(userName != null) {
          this.userService.getUserProfile().subscribe(user => {
                user.locale = this.getCodeFromLanguage(lang);
                this.userService.updateUserProfile(user).subscribe(response=>{
                },
                error => {
                });  
          },
          error => {
          });
        }
      }

  }

  public get currentLanguage():string {
    return this.translate.currentLang;
  }
  

  subscription: Subscription;

 
  ngOnInit(): void {
    let token = this.loginService.getAccessToken();
    if (token && token != '' && !this.loginService.getJWTHepler().isTokenExpired()) {
      let decodedToken: DecodedToken = this.loginService.initiateAccessToken(token);
      this.userLanguage = decodedToken.locale
    } 
    setInterval(() =>{
    if(token) { 
      if(this.loginService.getTokenExpirationtime()<=30){
        this.loginService.refreshToken().subscribe(
         token => {
          let accessToken: string = token[TOKEN_NAME];
           let refreshToken: string = token[REFRESH_TOKEN_NAME];
           this.loginService.setAccessToken(accessToken);
           this.loginService.setRefreshToken(refreshToken);
           this.loginService.initiateAccessToken(accessToken);
           this.storageService.updateStorageOnOtherTab(new Date());
         }) }
    }},30000);
    this.subscription = this.loginService.loginObservable()
    .subscribe((isLoggedIn:boolean)=>{
      if(isLoggedIn){
        this.reset();
      }
      else{
        this.idle.stop();
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public getCodeFromLanguage(language):string{
    let languageCode = '';
    switch (language) {
      case 'English':
        languageCode = 'en';
        break;
      case 'German':
        languageCode = 'de';
        break;
      case 'Spanish':
        languageCode = 'sp';
        break;
      case 'French':
        languageCode = 'fr';
        break;
      case 'Russian':
        languageCode = 'ru';
        break;
      case 'Chinese':
        languageCode = 'ch';
        break;      
      default :
        languageCode = 'en';
    
    }
    return languageCode;
  }

  public getLanguageFromCode(languageCode):string{
    let language = '';
    switch (languageCode) {
      case 'en':
        language = 'English';
        break;
      case 'de':
        language = 'German';
        break;
      case 'sp':
        language = 'Spanish';
        break;
      case 'fr':
        language = 'French';
        break;
        case 'ru':
        language = 'Russian';
        break;
        case 'ch':
        language = 'Chinese';
        break;
      default :
        language = 'English';
      
    }
    return language;
  }
  public getLanguages() {
     return this.languages;
  }
  /**
   * 
   * @param event This below event trigger each time during refresh event happen either press F5 or click browser reload icon.
   * After check if login user & observable variable not empty then set the value in session storage.
   */
  @HostListener("window:beforeunload", ["$event"]) unloadHandler(event: Event) {
    let isLoggedIn: boolean = this.loginService.isLoggedIn();
    if(isLoggedIn && this.sponsorProjectDropdownService.checkObservaEmpty()){
      this.sponsorProjectDropdownService.returnSponsorDropDownCache().subscribe((dropDownData)=>{
        sessionStorage.setItem("sponsorProjectData", JSON.stringify(dropDownData));
      });
    }
  }

  constructor( private element: ElementRef,
    private idle: Idle, private keepalive: Keepalive,private http: HttpClient,
    private loginService: LoginService, public translate: TranslateService, private userService: UserService,
    private sponsorProjectDropdownService: SponsorProjectDropdownService, private idleTimeService: IdleTimeService,
    private userConnectionService: UserConnectionService, private storageService: StorageService ) {
      /**
       * storage event this event trigger each time add or remove or update session & local storage variables.
       * In this code check if Access &Refresh Token is remove then reset the onservable variable null.
       * this function call heppend during when you open the page in multiple tabs in browser. if one of the page
       * logout then other tab trigger this event reset the observable variable.
       */
         
    idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'You will time out in ' + countdown + ' seconds!'
    });
    window.addEventListener('storage', () => {
      let tempAccessToken = this.loginService.getAccessToken();
      let tempRefreshToken = this.loginService.getRefreshToken();

      if((!tempAccessToken || !tempRefreshToken) && this.sponsorProjectDropdownService.checkObservaEmpty()){
        this.sponsorProjectDropdownService.resetObservable();
      }
    });

    translate.addLangs(LANGUAGECODES);
    this.languages = LANGUAGES;
    let defaultLanguage = storageService.getItem("language");
    
    if(defaultLanguage!=null) {
      translate.setDefaultLang(this.getCodeFromLanguage(defaultLanguage));
    } else {
      translate.setDefaultLang('en');
      storageService.setItem("language",'English');
    }
    this.userLanguage = 'en';

    LicenseManager.setLicenseKey("CompanyName=Neogenomics Laboratories,LicensedGroup=Pharma Services IT,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=25,LicensedProductionInstancesCount=2,AssetReference=AG-015337,ExpiryDate=26_June_2022_[v2]_MTY1NjE5ODAwMDAwMA==3845996ec59d13d7f4f88d97d23057f7");
    this.idleTimeService.getIdleProperties().subscribe(response=>{ 
      idle.setIdle(parseInt(response["setIdle"]));
      this.TimerTimeOut=parseInt(response["setTimeout"])*1000;
      idle.setTimeout(parseInt(response["setTimeout"]));
      idle.setInterrupts([
        new EventTargetInterruptSource(
          this.element.nativeElement, 'keydown DOMMouseScroll mousewheel mousedown touchstart touchmove scroll')]);
            idle.onTimeout.subscribe(() => {
            this.idleState = 'Timed out!';
            this.timedOut = true;
            this.idleTimeOutAction();
         });

          
      idle.onIdleEnd.subscribe(() => {
        this.idleState = 'NO_LONGER_IDLE';
      });
      idle.onIdleStart.subscribe(() => {
        this.idleState = 'IDLE_START', this.showSwal(1);
      });

      keepalive.interval(parseInt(response["setInterval"]));
    });
  }

  setUserPreferences(firstName: string, lastName: string, language: string) {
    this.userLanguage = language;
  }

  showSwal(count: number) {
    let timerInterval;
    Swal.fire({
      title: "Session Warning!",
      allowOutsideClick: false,
      html:  this.translate.instant("Session Expired Message"),
      timer: this.TimerTimeOut,
      onBeforeOpen: () => {
        timerInterval = setInterval(() => {
          const content = Swal.getContent()
          if (content) {
            const b = content.querySelector('b')
            if (b) {
              b.textContent = (Math.round(Swal.getTimerLeft()/1000)).toString();
            }
          }
        }, 1000)},
      showConfirmButton: true,
      showCancelButton: true,
      cancelButtonClass: "btn btn-success"
    }).then((result) => {
      if (result.value) {
        this.reset();
        this.loginService.refreshToken().subscribe(
          token => {
            let accessToken: string = token[TOKEN_NAME];
            let refreshToken: string = token[REFRESH_TOKEN_NAME];
            this.loginService.setAccessToken(accessToken);
            this.loginService.setRefreshToken(refreshToken);
            this.loginService.initiateAccessToken(accessToken);
            this.storageService.updateStorageOnOtherTab(new Date());
          },
          error=>{
            this.idleTimeOutAction();
          }
        );

      } else if (result.dismiss==Swal.DismissReason.cancel) {
        this.idleTimeOutAction();
      }
    })

  }
  reverseNumber(countdown: number) {
    return (300 - (countdown - 1));
  }

  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }
  idleTimeOutAction(){
    let connectionId = sessionStorage.getItem('connectionId');
    if(connectionId != null) {
        this.userConnectionService.updateUserConnection(connectionId)
        .toPromise()
          .then((res)=> {
              sessionStorage.removeItem('connectionId');
              this.loginService.logoutAndDestroyToken();
          }).catch(err => {
          }); 
    }
   
  }

}