import { Component, OnInit, Renderer, ViewChild, ElementRef, Directive, HostListener, OnDestroy } from '@angular/core';
import { ROUTES, TOPNAVDROPDOWN } from '../.././sidebar/sidebar.component';
import { Router, NavigationEnd } from '@angular/router';
import { Location} from '@angular/common';
import { Subscription } from 'rxjs/Subscription';
import { LoginComponent } from '../../pages/login/login.component'
import { AppComponent } from '../../app.component';
import { AddinvestigatorService } from '../../addinvestigator/addinvestigator.service';
import { LoginService } from '../../services/login.service';
import { ROLE_NEOPM, ROLE_SYSADMIN, ROLE_DATAMANAGER, NEWORDERPAGE, ROLE_INVPM } from '../pharmaportal.constant';
import { TranslateService } from '@ngx-translate/core';
import { UserConnectionService } from 'app/services/userconnection.service';
import { StorageService } from 'app/services/storage.service';
import { OrderService } from 'app/order/order.service';
import {EventBusService} from '../../services/event-bus.service'
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'environments/environment';
var misc: any = {
    navbar_menu_visible: 0,
    active_collapse: true,
    disabled_collapse_init: 0,
}

@Component({
    moduleId: module.id,
    selector: 'navbar-cmp',
    templateUrl: 'navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent implements OnInit,OnDestroy  {
    private listTitles: any[];
	private listTopNavTitles : any[];
    location: Location;
    private nativeElement: Node;
    private toggleButton;
    private sidebarVisible: boolean;
    private _router: Subscription;
    public open: boolean = false;
    private login: LoginComponent;
    userid: string;
    userDisplayName = '';
    public titleDescrption='';
    isInternalUsr: false;
    subscription:Subscription;
    scrollStatsMonitor="";
    public userType: String;
    public userDisplayType:String;
    public isInternalLogin :boolean=true;
    public userTypeListCount: any;
    public isInternalUserCount :boolean=true;


    @ViewChild("navbar-cmp", { static: false }) button;

    constructor(
        public mainApp: AppComponent,
        location: Location,
        private renderer: Renderer,
        private element: ElementRef,
        private router: Router,
        private addinvestigatorService: AddinvestigatorService,
        private loginService: LoginService,
        public translate: TranslateService,
        private userConnectionService: UserConnectionService,
        private storageService: StorageService,
        private orderService : OrderService,
        private evtsrvc:EventBusService,
        private http: HttpClient,
        
    ) {
        this.location = location; 
        this.nativeElement = element.nativeElement;
        this.sidebarVisible = false;
        this.subscription=this.evtsrvc.getScrollStats().subscribe(result=>{
            if(result){
               this.scrollStatsMonitor=result ;
            }else{
                this.scrollStatsMonitor="";
            }
        })
    }
    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
    public localStorageItem(id: string): string {
        return this.storageService.getItem(id);
    }

    ngOnInit() {  
        this.listTitles = ROUTES.filter(listTitle => listTitle);
		this.listTopNavTitles=TOPNAVDROPDOWN.filter(listTopNavTitle=> listTopNavTitle);     
        this.userDisplayName = sessionStorage.getItem('loggedUser');
        const navbar: HTMLElement = this.element.nativeElement;
        const body = document.getElementsByTagName('body')[0];
        this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
        if (body.classList.contains('sidebar-mini')) {
            misc.sidebar_mini_active = true;
        }
        this._router = this.router.events.filter(event => event instanceof NavigationEnd).subscribe((event: NavigationEnd) => {
            const $layer = document.getElementsByClassName('close-layer')[0];
            if ($layer) {
                $layer.remove();
            }
        });

        this.addinvestigatorService.closeSideBarAlert$.subscribe(flagStatus => {
            this.sidebarClose();
        });
        let decodedToken = this.loginService.getJWTHepler().decodeToken(this.loginService.getAccessToken());
        
        /**
         * During login as investigator user sidebar can minimized.
         * check if the role as investigator minimize the sidebar otherwise
         * maxmize the sidebar.
         */
        if(decodedToken.authorities.includes("ROLE_INVESTIGATOR")){
            if (misc.sidebar_mini_active != true) {
                this.minimizeSidebar();
            }
        }else{
            if (misc.sidebar_mini_active === true) {
                this.minimizeSidebar();
            }
            
        }

        this.userType = sessionStorage.getItem('loggedUserType');
        this.userDisplayType = sessionStorage.getItem('loggedUser');
        switch(this.userType){   
            case 'neopm':
              this.userDisplayType='BioPharma Project Manager';
              break;
            case 'invpm':
              this.userDisplayType='Inivata Project Manager';
              break;
            case 'datamanager':
              this.userDisplayType='Data Manager';
              break;
            case 'sysadmin':
              this.userDisplayType='System Admin';
              break;            
          }
        
          if(this.storageService.getItem("loginStatus")=="false" ){
            this.isInternalLogin = false;
          }
          this.loginService.fetchUserRoles().subscribe(res => {
            this.userTypeListCount = Object.keys(res).length;
            if(this.userTypeListCount <=1){
                this.isInternalUserCount = false;
              }
          })
    
 }

    minimizeSidebar() {
        const body = document.getElementsByTagName('body')[0];

        if (misc.sidebar_mini_active === true) {
            body.classList.remove('sidebar-mini');
            misc.sidebar_mini_active = false;
        } else {
            setTimeout(function () {
                body.classList.add('sidebar-mini');
                misc.sidebar_mini_active = true;
            }, 300);
        }
        

        // we simulate the window Resize so the charts will get updated in realtime.
        const simulateWindowResize = setInterval(function () {
            window.dispatchEvent(new Event('resize'));
        }, 180);

        // we stop the simulation of Window Resize after the animations are completed
        setTimeout(function () {
            clearInterval(simulateWindowResize);
        }, 1000);
    }

    isMobileMenu() {
        if (window.outerWidth < 991) {
            return false;
        }
        return true;
    }

    sidebarOpen() {
        var toggleButton = this.toggleButton;
        //This part of code close the right nav dropdown.
        var rightNavBar = document.getElementsByClassName('dropdowncloseopen')[0];
        if(rightNavBar){
            rightNavBar.classList.remove('collapsing');
            rightNavBar.classList.add('collapse');
        }
        var html = document.getElementsByTagName('html')[0];
        setTimeout(function () {
            toggleButton.classList.add('toggled');
        }, 500);
        const mainPanel = <HTMLElement>document.getElementsByClassName('main-panel')[0];
        if (window.innerWidth < 991) {
            mainPanel.style.position = 'fixed';
        }
        html.classList.add('nav-open');
        this.sidebarVisible = true;
    }
    sidebarClose() {
        var html = document.getElementsByTagName('html')[0];
        this.toggleButton.classList.remove('toggled');
        this.sidebarVisible = false;
        html.classList.remove('nav-open');
        const mainPanel = <HTMLElement>document.getElementsByClassName('main-panel')[0];

        if (window.innerWidth < 991) {
            setTimeout(function () {
                mainPanel.style.position = '';
            }, 500);
        }
    }
    sidebarToggle() {
        if (this.sidebarVisible == false) {
            this.sidebarOpen();
        } else {
            this.sidebarClose();
        }
    }



    getTitle() {
        var titlee = this.location.prepareExternalUrl(this.location.path());
        if (titlee.charAt(0) === '#') {
            titlee = titlee.slice(1);
        }

        let filterTitle=titlee.split("/")
        for(let title of filterTitle){
            if(title===NEWORDERPAGE){  
                this.titleDescrption="";
                return 'Online Order';
            }
        }
        for (var item = 0; item < this.listTitles.length; item++) {
            var parent = this.listTitles[item];            
            if (parent.path === titlee) {
                this.titleDescrption = parent.discription;
                return parent.title;
            } else if (parent.children) {
                var children_from_url =titlee;               
                if(parent.path){
                    var titleSeg = titlee.split("/");
                   children_from_url = titlee.split("/")[2];
                 
                   if((children_from_url === 'manageuser' || children_from_url === 'VendorAdministration') && titlee.split("/")[3]){
                    children_from_url = titlee.split("/")[3];
                   }
                }
                for (var current = 0; current < parent.children.length; current++) {
                    if (parent.children[current].path === children_from_url) {
                        this.titleDescrption = parent.children[current].discription;
                        return  parent.children[current].title;
                    }
                }
            }
        }
        for (var item = 0; item < this.listTopNavTitles.length; item++) {
            if (this.listTopNavTitles[item].path === titlee) {
                this.titleDescrption=this.listTopNavTitles[item].description;
                return this.listTopNavTitles[item].title;
            }
        }     
        
   return 'Dashboard';
    }

    getPath() {
        return this.location.prepareExternalUrl(this.location.path());
    }
    logout() {
        this.storageService.removeItem("loginStatus");
        this.storageService.removeItem("language");
        this.translate.use('en');
        let connectionId = sessionStorage.getItem('connectionId');
        if(connectionId != null) {
            this.userConnectionService.updateUserConnection(connectionId)
            .toPromise()
              .then((res)=> {
              }).catch(err => {
              }); 
        }
        sessionStorage.removeItem('connectionId');
        if (this.getPath().includes("/order/")) {
            let path = this.getPath().split("/");
            const formData = new FormData();
            formData.append("orderId", path[2]);
            this.orderService.releaseOrderLock(formData).subscribe(data => {
                this.loginService.logoutAndDestroyToken();
            },
            error=>{
                this.loginService.logoutAndDestroyToken();
            });
        } else {
            this.loginService.logoutAndDestroyToken();
        }
        }

    isInternalUser(): boolean{
        try {
            let decodedToken = this.loginService.getJWTHepler().decodeToken(this.loginService.getAccessToken());
            this.isInternalUsr= decodedToken.authorities.includes(ROLE_NEOPM) || decodedToken.authorities.includes(ROLE_DATAMANAGER) 
            || decodedToken.authorities.includes(ROLE_SYSADMIN) || decodedToken.authorities.includes(ROLE_INVPM);
        } catch(e){}
        return this.isInternalUsr;

    }
    isLoggedInUser() : boolean{
       return (!sessionStorage.getItem("loggedUserType")===null);
    }

    signWithOtherRole(){
       this.router.navigate(['/login/loginAs']);
    }
}
