<footer class="footer cus-footer">
    <div class="container-fluid row justify-content-center pl-5 pr-4">
        <nav class="pull-left pull-center">
            <ul>

                <li class="pr-5">
                    &copy; NeoGenomics Laboratories, Inc.
                </li>

                <li>
                    <a href="https://neogenomics.com/company/contact-us">
                        {{'Contact Us' | translate}}
                    </a>
                </li>|
                <li>
                    <a href="https://neogenomics.com/careers">
                        {{'Careers' | translate}}
                    </a>
                </li>|
                <li>
                    <a href="https://neogenomics.com/company/regulatory-and-licensing">
                        {{'Licensing' | translate}}
                    </a>
                </li>|

                <li>
                    <a href="https://neogenomics.com/company/regulatory-and-licensing/terms-of-use">
                        {{'Terms' | translate}}
                    </a>
                </li>|

                <li class="pr-3">
                    <a href="https://neogenomics.com/company/regulatory-and-licensing/website-privacy-policy">
                        {{'Privacy' | translate}}
                    </a>
                </li>
                <li>
                    <div class="picture">
                        <img src="./assets/img/cap-clia-logo.png">
                    </div>
                </li>
            </ul>
        </nav>
    </div>
</footer>