import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ConvertToSpacesPipe } from './convert-to-spaces.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { PasswordValidatorDirective } from './directives/password-validator.directive';
import { RptpasswordValidatorDirective } from './directives/rptpassword-validator.directive';


@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    ConvertToSpacesPipe,
    PasswordValidatorDirective,
    RptpasswordValidatorDirective
  ],
  exports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    PasswordValidatorDirective,
    RptpasswordValidatorDirective
  ]
})
export class SharedModule { }
