import { Directive, ElementRef, HostListener, OnInit, Renderer2 } from "@angular/core"
import { NavigationEnd, Router } from "@angular/router";

@Directive({
    selector: '[sideBarScroll]'
  })
export class sideBarScrollDirective implements OnInit {
    routeChanged:boolean;
    currentScrollPosition:any=0;
    constructor(private el: ElementRef,
            private router: Router) {
            localStorage.setItem("currentScrollPosition",this.currentScrollPosition);
    }
    ngOnInit(): void {
        this.router.events.subscribe((val) => { 
            this.routeChanged=val instanceof NavigationEnd;
            if(this.routeChanged){
                let newScrollPos=this.el.nativeElement.scrollTop;
                newScrollPos=+localStorage.getItem("currentScrollPosition");

            } 
        });
    }
    @HostListener('scroll') onScroll() { 
        this.currentScrollPosition = this.el.nativeElement.scrollTop;
        localStorage.setItem("currentScrollPosition",this.currentScrollPosition);
    }

  }

