import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';
import { catchError } from 'rxjs/operators';
import { ErrorHandlerService } from './error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class UserConnectionService {

  private httpOptions: any;

  constructor(private http: HttpClient, private errorHandlerService : ErrorHandlerService) { }
  
  createUserConnection(createConnectionRequest : any ) {
    this.httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json'})
    };
    let url = environment.apiUrl+"/api/userconnection/createUserConnection" ;
    return this.http.post(url, createConnectionRequest, this.httpOptions)
    .pipe(
      catchError(this.errorHandlerService.errorHandler)
    );
  }

  updateUserConnection(connectionId: string) {
    
    let url = environment.apiUrl+"/api/userconnection/updateUserConnection/" ;
    return this.http.put(url, null,
      {
        params :{
          connectionId : connectionId
        }
      })
    .pipe(
      catchError(this.errorHandlerService.errorHandler)
    );
  }
}
