import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { UserProfile } from './userprofile.model';
import { catchError } from 'rxjs/operators';
import { ErrorHandlerService } from 'app/services/error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private _jsonURL = 'assets/timeZone.json';
  constructor(private http: HttpClient, private errorHandlerService : ErrorHandlerService) { }

  getUserProfile(): Observable<UserProfile> {
    return this.http.get<UserProfile>(environment.apiUrl + "/api/user/users/getUserProfile");
  }
  public updateUserProfile(updateUser: UserProfile) {
    let urlData = environment.apiUrl + "/api/user/updateUserProfile";
    return this.http.put(urlData, updateUser).pipe(
      catchError(this.errorHandlerService.errorHandler));
  }
  public getJSON(): Observable<any> {
    return this.http.get(this._jsonURL);
  }

}