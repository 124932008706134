import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from 'environments/environment';
import { Observable, Subject } from "rxjs";
import { CLIENT, CLIENT_CRED, TOKEN_NAME, REFRESH_TOKEN_NAME, INTERNAL_USER_LOGOUT_URL } from "./auth.constant";
import { DecodedToken } from "./tokenModel";
import { JwtHelperService } from "@auth0/angular-jwt";
import { Router } from "@angular/router";
import { RoleService } from "./guard/role.service";
import { DataService } from "app/order/data.service";
import { SPONSORPROJECTDATA } from '../shared/pharmaportal.constant';
import { StorageService } from "./storage.service";
import { SponsorProjectDropdownService } from "app/shared/components/sponsor-project-dropdown/sponsor-project-dropdown.service";

@Injectable({
    providedIn: 'root'
})
export class LoginService {
    static TOKEN_ENDPOINT = '/oauth/token';

    baseUrl = environment.apiUrl;
    userDetails: any;
    lastValidityCheckTimeStamp: Date = null;

    decodedToken: DecodedToken = null;

    private dataExport_DtoReportObject: any =[];

    private jwtHelper: JwtHelperService = new JwtHelperService({
        tokenGetter: this.getAccessToken.bind(this)
    });

    private subject: Subject<boolean> = new Subject();

    constructor(private http: HttpClient, private router: Router, private roleService: RoleService,
        private data: DataService, private storageService: StorageService,private dropdownService: SponsorProjectDropdownService) { }

    login(username: string, password: string) {
        const body = `username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}&grant_type=password`;



        const data: FormData = new FormData();

        data.append('username', username);
        data.append('password', password);
        data.append('grant_type', 'password');
        data.append('Authorization', 'Basic ' + btoa(CLIENT + ':' + CLIENT_CRED));
        return this.http.post(this.baseUrl + LoginService.TOKEN_ENDPOINT, data);
    }
    logout(): Observable<any> {
        const data: FormData = new FormData();
        data.append('token',this.getAccessToken());
        return this.http.post(this.baseUrl + "/api/security/logout", data, { responseType: 'text' });
    }

    getEnvironment(){
        return this.http.get(this.baseUrl+"/api/security/getAppEnvironment");
    }
    setUserDetails(users: any): void {
        this.userDetails = users;
        this.lastValidityCheckTimeStamp = new Date();
    }

    clearUserDetails() {
        this.userDetails = null;
        this.lastValidityCheckTimeStamp = null;
    }

    initiateAccessToken(access_token: string): DecodedToken {
        this.setAccessToken(access_token);
        let decodedToken = this.jwtHelper.decodeToken(access_token);
        this.decodedToken = decodedToken;
        sessionStorage.setItem('loggedUser', decodedToken.user_name);
        sessionStorage.setItem('loggedUserType', decodedToken.user_type);
        this.roleService.setRoles(this.decodedToken.authorities);
        return decodedToken;
    }

    isLoggedIn(): boolean {
        return !this.jwtHelper.isTokenExpired();
    }
    getTokenExpirationtime(): number { 
        if (this.decodedToken === undefined) return null;
        const expDate = new Date(0); 
        expDate.setUTCSeconds(this.decodedToken.exp);
        let seconds = (expDate.getTime() - new Date().getTime()) / 1000;
        return seconds;
      }
    isRefreshTokenValid() {
        const helper = new JwtHelperService();
        const refreshToken = sessionStorage.getItem('refresh_token');
        return helper.isTokenExpired(refreshToken);
    }

    getJWTHepler(): JwtHelperService {
        return this.jwtHelper;
    }

    performInitialNavigation() {
       
        if (this.decodedToken) {
            
            if (this.decodedToken.user_type === 'sysadmin') {
                this.router.navigate(['/systemAdmin/manageExternalUsers']);
            }else if (this.decodedToken.user_type === 'datamanager') {
                this.router.navigate(['/dataManager/manageDTA']);
            }
            else {
                this.router.navigate(['/reports/maindashboard']);
            }
        } else {
            this.router.navigate(['/pages/login']);
        }
    }

    getAccessToken() {
        return this.storageService.getItem(TOKEN_NAME);
    }

    setAccessToken(token: string) {
        this.storageService.setItem(TOKEN_NAME, token);
    }

    getRefreshToken() {
        return this.storageService.getItem(REFRESH_TOKEN_NAME);
    }

    setRefreshToken(token: string) {
        this.storageService.setItem('refresh_token', token);
    }

    setInternalUserLogoutURL(url: string){
        this.storageService.setItem(INTERNAL_USER_LOGOUT_URL, url);
    }

    logoutAndDestroyToken() {
        this.propagateLoginSuccess(false);
        this.logout().subscribe(data=>{
            this.navigateAndDestroyToken();
        },error=>{
            this.navigateAndDestroyToken();
        });        
    }

    navigateAndDestroyToken(){
        this.data.islogout = true;
        
            sessionStorage.removeItem(SPONSORPROJECTDATA);
            this.storageService.removeItem(TOKEN_NAME);
            this.storageService.removeItem(REFRESH_TOKEN_NAME);
            sessionStorage.removeItem('loggedUser');
            if (sessionStorage.getItem('loggedUserType') === 'neopm' || sessionStorage.getItem('loggedUserType') === 'sysadmin') {
                sessionStorage.removeItem('loggedUserType');
                let internalUserLogoutUrl = this.storageService.getItem(INTERNAL_USER_LOGOUT_URL);
                window.location.href = internalUserLogoutUrl;
            }
            else {
                sessionStorage.removeItem('loggedUserType');
                this.router.navigate(['/pages/login'])
            };
    }


    refreshToken() {

        const data: FormData = new FormData();

        data.append('client_id', CLIENT);
        data.append('client_secret', CLIENT_CRED);
        data.append('grant_type', encodeURIComponent('refresh_token'));
        data.append('refresh_token', this.getRefreshToken());
        data.append('Authorization', 'Basic ' + btoa(CLIENT + ':' + CLIENT_CRED));

        return this.http.post(this.baseUrl + LoginService.TOKEN_ENDPOINT, data);
    }

    propagateLoginSuccess(isLoggedin:boolean){
        this.subject.next(isLoggedin);
    }

    loginObservable():Observable<boolean>{
        return this.subject.asObservable();
    }

    getLoggedInUserName():string{
        this.decodedToken = this.jwtHelper.decodeToken(this.getAccessToken());
        return this.decodedToken.user_name;
    }

    getUserGuide(): any {
        const httpOptions = {
          responseType: 'arraybuffer' as 'json'
        };
        return this.http.get<any>(`${this.baseUrl}/api/dashboard/getUserGuide`, httpOptions);
      }

    forgetPassword(username) {
        return this.http.get<any>(`${this.baseUrl}/api/user/reset/${username}/anonymousUser`)
    }
      getSecureAuthProperties(){
          return this.http.get<any>(environment.apiUrl+'/api/security/getSecureAuthProperties');
      }

      dataexport_DtaReportId(url:string, reqReportId :string, requestCategory: string){
        this.dataExport_DtoReportObject= {
            "url":url,
            "reqReportId": reqReportId,
            "requestCategory": requestCategory
        };
      }

      dataexport_DtaReportReturn(){
        return this.dataExport_DtoReportObject;
      }
      accountConfirm(token): Observable<any> {
        const data: FormData = new FormData();   
        return this.http.post(this.baseUrl + "/api/user/checkuniqueConfirmId/"+token,data);
    }

    loadBgVideo(){
        const httpOptions = {
            responseType: 'blob' as 'json'
        };
        return this.http.get<any>(`${this.baseUrl}/api/security/loadBgVideo`,httpOptions);
    }
    generateToken(userType: string){
        let params = new HttpParams();
        params = params.append('userType',userType)
        params = params.append('token',this.getAccessToken());
        this.dropdownService.resetObservable();
        sessionStorage.removeItem("sponsorProjectData");
        return this.http.get(environment.apiUrl+'/api/security/generateToken', { params,responseType: 'text'  });
    }
    fetchUserRoles(){
        return this.http.get<any>(environment.apiUrl+'/api/security/fetchUserRoles');
    }
    getDynamicUrl(){
        return this.http.get(environment.apiUrl+'/api/security/fetchDynamicUrl', { responseType: 'text'  });
    }
}