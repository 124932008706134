/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./confirm-leave.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "./confirm-leave.component";
import * as i4 from "ngx-bootstrap/modal";
import * as i5 from "../data.service";
var styles_ConfirmLeaveComponent = [i0.styles];
var RenderType_ConfirmLeaveComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConfirmLeaveComponent, data: {} });
export { RenderType_ConfirmLeaveComponent as RenderType_ConfirmLeaveComponent };
export function View_ConfirmLeaveComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 20, "div", [["class", "modal-content modal-content-small auto-width"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "modal-header modal-block-primary"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "button", [["aria-hidden", "true"], ["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.bsModalRef.hide() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "i", [["class", "nc-icon nc-simple-remove"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "h5", [["class", "modal-title"], ["id", "specLabel"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(8, 0, null, null, 5, "div", [["class", "modal-body clearfix adjustPadding"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 4, "div", [["class", "modal-text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 3, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, ["", " '", "' ", "?"])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(14, 0, null, null, 6, "div", [["class", "modal-footer adjustPadding"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 2, "button", [["class", "btn btn-neo-blue btn-round"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.action(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(16, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(18, 0, null, null, 2, "button", [["class", "btn btn-neo-blue btn-round"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.action(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(19, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform("order.alert")); _ck(_v, 6, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 11, 0, i1.ɵnov(_v, 12).transform("order.orderid")); var currVal_2 = _co.orderid; var currVal_3 = i1.ɵunv(_v, 11, 2, i1.ɵnov(_v, 13).transform("order.submitdisclaimer")); _ck(_v, 11, 0, currVal_1, currVal_2, currVal_3); var currVal_4 = i1.ɵunv(_v, 16, 0, i1.ɵnov(_v, 17).transform("order.yes")); _ck(_v, 16, 0, currVal_4); var currVal_5 = i1.ɵunv(_v, 19, 0, i1.ɵnov(_v, 20).transform("order.no")); _ck(_v, 19, 0, currVal_5); }); }
export function View_ConfirmLeaveComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-confirm-leave", [], null, null, null, View_ConfirmLeaveComponent_0, RenderType_ConfirmLeaveComponent)), i1.ɵdid(1, 114688, null, 0, i3.ConfirmLeaveComponent, [i4.BsModalRef, i5.DataService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ConfirmLeaveComponentNgFactory = i1.ɵccf("app-confirm-leave", i3.ConfirmLeaveComponent, View_ConfirmLeaveComponent_Host_0, {}, {}, []);
export { ConfirmLeaveComponentNgFactory as ConfirmLeaveComponentNgFactory };
