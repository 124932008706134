import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';
import { catchError } from 'rxjs/operators';
import { ErrorHandlerService } from './error-handler.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./error-handler.service";
var UserConnectionService = /** @class */ (function () {
    function UserConnectionService(http, errorHandlerService) {
        this.http = http;
        this.errorHandlerService = errorHandlerService;
    }
    UserConnectionService.prototype.createUserConnection = function (createConnectionRequest) {
        this.httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' })
        };
        var url = environment.apiUrl + "/api/userconnection/createUserConnection";
        return this.http.post(url, createConnectionRequest, this.httpOptions)
            .pipe(catchError(this.errorHandlerService.errorHandler));
    };
    UserConnectionService.prototype.updateUserConnection = function (connectionId) {
        var url = environment.apiUrl + "/api/userconnection/updateUserConnection/";
        return this.http.put(url, null, {
            params: {
                connectionId: connectionId
            }
        })
            .pipe(catchError(this.errorHandlerService.errorHandler));
    };
    UserConnectionService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserConnectionService_Factory() { return new UserConnectionService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.ErrorHandlerService)); }, token: UserConnectionService, providedIn: "root" });
    return UserConnectionService;
}());
export { UserConnectionService };
