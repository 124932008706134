import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
import { throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {
  translate: any
  private errHead= "Error";
  private butnOk="OK";
  private errTypeWar="warning";
  constructor(translator: TranslateService) { 
    this.translate = translator;
  }
  public errorHandler(httpErrorResponse: HttpErrorResponse){

   if(httpErrorResponse.status != 401 && httpErrorResponse.status != 403 && !httpErrorResponse.url.endsWith('/oauth/token')){
    if(httpErrorResponse.error && httpErrorResponse.error.userMessage){
        let errorMessage = httpErrorResponse.error.userMessage;
       
        Swal.fire({
          title: "Error",
          text: errorMessage,
          type: "warning",
          confirmButtonText: "OK",
          confirmButtonClass: "btn btn-danger"
      });
      }else{
        var erroHead = httpErrorResponse.error.error;
        var errorMessage= httpErrorResponse.error.message;
        Swal.fire({
          title: erroHead != undefined ? erroHead.toString().length >100 ? erroHead.toString().substring(1,100): erroHead.toString(): 'Error',
          text: errorMessage != undefined ? errorMessage: 'Something went wrong please try again later.',
          type: "error",
          confirmButtonText: "OK",
          confirmButtonClass: "btn btn-danger"
      });
      } 
    }
  return throwError(httpErrorResponse);
  }
  /**
   * If the error response type is blob format call this method. 
   * In this method extract the error text from blob and show the translated error message in popup.
   * @param httpErrorResponse 
   */
  public errorParseBlobtoJson(httpErrorResponse: HttpErrorResponse){
    if(httpErrorResponse.error instanceof Blob){
      var reader = new FileReader();
      reader.onload = (function(f) {
        return function(e) {
          var parseJson= JSON.parse((<any>e.target).result);
          if(parseJson.userMessage){
            f.translate.get(parseJson.userMessage.trim()).subscribe(data=>{
              f.swalError_Warning(f.translate.instant(f.errHead), data, f.errTypeWar, f.translate.instant(f.butnOk));
            });
          }
        };
      })(this);

      reader.readAsText(httpErrorResponse.error);
      

    }
  return throwError(httpErrorResponse);
  }
  /**
   * In this method translate the runtime java exception error message and 
   * showed in popup.If the "errorMessage" exist in translation json then 
   * retun that key related translated text.Otherwise return the same text. 
   * @param httpErrorResponse 
   */
  public errorHandlerWithTranslator(httpErrorResponse: HttpErrorResponse){
    //Got a properly formatted Pharmaportal Exception with userMessage
    if(httpErrorResponse.status != 401 && httpErrorResponse.status != 403 && !httpErrorResponse.url.endsWith('/oauth/token')){
      if(httpErrorResponse.error && httpErrorResponse.error.userMessage){
        if(httpErrorResponse.error.userMessage.includes("locked by")){
          let lockedby=httpErrorResponse.error.userMessage.split(":");
          let errorMessage=this.translate.instant("The order is locked by").replace("{username}",lockedby[1]);
          this.swalError_Warning(httpErrorResponse.error.error, errorMessage, 'error', 'OK');
        } else {
        let errorMessage = httpErrorResponse.error.userMessage.trim();
        this.translate.get(errorMessage).subscribe(data=>{
          this.swalError_Warning(this.translate.instant(this.errHead), data, this.errTypeWar, this.translate.instant(this.butnOk));
        });
      }
      }else {
        this.swalError_Warning(httpErrorResponse.error.error, httpErrorResponse.error.message, 'error', 'OK');
      } 
    }
  return throwError(httpErrorResponse);
  }

  public swalError_Warning(erroHead: any, errorMessage: any, errorType: any, okHead: any){
    Swal.fire({
      title: erroHead != undefined ? erroHead.toString().length >100 ? erroHead.toString().substring(1,100): erroHead.toString(): this.translate.instant('Error'),
      text: errorMessage != undefined ? errorMessage: this.translate.instant('SOMETHING_WENT_WRONG') ,
      type: errorType,
      confirmButtonText: this.translate.instant(okHead),
      confirmButtonClass: "btn btn-danger"
    });
  }
}
