<nav #navbar [ngClass]="{'fixed-header':scrollStatsMonitor=='scrolled'}" class="navbar navbar-expand-lg nav-bar-back-color navbar-transparent nav-height-temp">
    <div class="container-fluid">
        <div class="navbar-wrapper">
            <div class="navbar-minimize">
                <button id="minimizeSidebar" class="btn btn-icon btn-round" (click)="minimizeSidebar()">
          <i class="nc-icon nc-minimal-right text-center visible-on-sidebar-mini"></i>
          <i class="nc-icon nc-minimal-left text-center visible-on-sidebar-regular"></i>
        </button>
            </div>
            <div class="navbar-toggle" (click)="sidebarToggle()">
                <button type="button" class="navbar-toggler">
          <span class="navbar-toggler-bar bar1"></span>
          <span class="navbar-toggler-bar bar2"></span>
          <span class="navbar-toggler-bar bar3"></span>
        </button>
            </div>
            <div class="navbar-brand">{{getTitle() | translate}}  <small class=" white-space-pre-wrap text-muted no-transform">{{titleDescrption | translate}}</small></div>
        </div>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navigation" aria-controls="navigation-index" aria-expanded="false" aria-label="Toggle navigation" (click)="open = !open">
      <span class="navbar-toggler-bar navbar-kebab"></span>
      <span class="navbar-toggler-bar navbar-kebab"></span>
      <span class="navbar-toggler-bar navbar-kebab"></span>
    </button>

        <div class="collapse navbar-collapse justify-content-end dropdowncloseopen" [ngClass]="{'collapsing bg-white': open === true , 'collapse': open === false}" id="navigation">
            <span class="navbar-nav">
                <select class="navbar-nav" #selectedLang (change)="this.mainApp.switchLang(selectedLang.value)">
                  <option *ngFor="let language of this.mainApp.getLanguages()" [value]="language"
                    [selected]="language === localStorageItem('language')">
                      {{translate.instant(language)}}
                  </option>
                </select>
            </span>
       
            <ul class="navbar-nav">
                <li class="nav-item dropdown">
                    <a class="font-size-15 pl-2 nav-link dropdown-toggle active-li" href="pablo" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="nc-icon nc-single-02 opacity-1 font-weight-600"></i>
                        <p>
                            <span class="d-lg-block d-md-block text-lowercase mr-1"> <b>{{userDisplayName}}</b></span>
                        </p>
                           <span *ngIf ="isInternalLogin && isInternalUserCount" style ="text-transform: capitalize;"><br><b>Role : {{userDisplayType}} </b></span>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
                        <a class="dropdown-item" href="#/users/user" *ngIf="!isInternalUser()">{{'Edit Profile' | translate}}</a>
                        <a class="dropdown-item" href="#/forms/validation" *ngIf="!isInternalUser()">{{'Change Password' | translate}}</a>
                        <a *ngIf ="isInternalLogin && isInternalUserCount" class="dropdown-item"  (click)="signWithOtherRole()">{{'Sign In With Different Role' | translate}}</a>

                        <a class="dropdown-item" href="javascript:void(0)" (click)="logout()">{{'Logout' | translate}}</a>
                    </div>


                </li>
            </ul>
        </div>
    </div>
</nav>