import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
var EventBusService = /** @class */ (function () {
    function EventBusService() {
        this.subject$ = new Subject();
    }
    EventBusService.prototype.emitScrollStat = function (event) {
        this.subject$.next(event);
    };
    EventBusService.prototype.getScrollStats = function () {
        return this.subject$.asObservable();
    };
    EventBusService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EventBusService_Factory() { return new EventBusService(); }, token: EventBusService, providedIn: "root" });
    return EventBusService;
}());
export { EventBusService };
