import { OnInit } from '@angular/core';
import { AppComponent } from '../app.component';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { LoginService } from 'app/services/login.service';
import { UserService } from './user.service';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { StorageService } from 'app/services/storage.service';
import { LANGUAGE_DISCLAIMER_TEXT } from 'app/shared/pharmaportal.constant';
import * as i0 from "@angular/core";
import * as i1 from "../app.component";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/router";
import * as i4 from "../services/login.service";
import * as i5 from "./user.service";
import * as i6 from "@angular/common/http";
import * as i7 from "../services/storage.service";
var UserComponent = /** @class */ (function () {
    function UserComponent(mainApp, translator, router, loginService, userService, http, storageService) {
        var _this = this;
        this.mainApp = mainApp;
        this.translator = translator;
        this.router = router;
        this.loginService = loginService;
        this.userService = userService;
        this.http = http;
        this.storageService = storageService;
        this.timeZones = [];
        this.timeZone = new Date().toTimeString().slice(19, (new Date().toTimeString().length) - 1);
        this.userService.getUserProfile().subscribe(function (response) {
            _this.user = response;
            _this.user.timezone !== null ? _this.timeZone = _this.user.timezone : _this.timeZone = _this.timeZone;
            _this.selectedLanguage = _this.mainApp.getLanguageFromCode(_this.user.locale);
        });
        this.userService.getJSON().subscribe(function (data) {
            data.forEach(function (element) {
                _this.timeZones.push(element);
            });
        });
    }
    UserComponent.prototype.showSwal = function (titile) {
        Swal.fire({
            title: titile,
            toast: true,
            position: 'top',
            timer: 3000,
            type: "success",
            showConfirmButton: false
        });
    };
    UserComponent.prototype.ngOnInit = function () {
    };
    UserComponent.prototype.save = function (userProfile, isValid) {
        var _this = this;
        userProfile.locale = this.user.locale;
        userProfile.timezone = this.timeZone;
        userProfile.portaluserName = this.loginService.getLoggedInUserName();
        if (isValid) {
            this.userService.updateUserProfile(userProfile).subscribe(function (response) {
                _this.showSwal(_this.translator.instant("Saved Succesfully"));
                _this.mainApp.switchLang(userProfile.locale);
            });
        }
    };
    UserComponent.prototype.cancel = function () {
        var _this = this;
        Swal.fire({
            text: this.translator.instant("Page Leave alert"),
            type: 'warning',
            showConfirmButton: true,
            showCancelButton: true,
            cancelButtonText: this.translator.instant("Cancel"),
            confirmButtonText: this.translator.instant("Yes"),
            cancelButtonClass: 'btn btn-sucess',
            confirmButtonClass: "btn btn-danger"
        }).then(function (result) {
            if (result.value) {
                _this.router.navigate(['/reports/maindashboard']);
            }
        });
    };
    UserComponent.prototype.changeLanguage = function () {
        var languageCode = this.mainApp.getCodeFromLanguage(this.selectedLanguage);
        this.user.locale = languageCode;
        Swal.fire('Disclaimer!', LANGUAGE_DISCLAIMER_TEXT, 'warning');
    };
    UserComponent.prototype.localStorageItem = function (id) {
        return this.storageService.getItem(id);
    };
    UserComponent.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserComponent_Factory() { return new UserComponent(i0.ɵɵinject(i1.AppComponent), i0.ɵɵinject(i2.TranslateService), i0.ɵɵinject(i3.Router), i0.ɵɵinject(i4.LoginService), i0.ɵɵinject(i5.UserService), i0.ɵɵinject(i6.HttpClient), i0.ɵɵinject(i7.StorageService)); }, token: UserComponent, providedIn: "root" });
    return UserComponent;
}());
export { UserComponent };
