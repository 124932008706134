import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap';
import { DataService } from '../data.service';

@Component({
  selector: 'app-confirm-leave',
  templateUrl: './confirm-leave.component.html',
  styleUrls: ['./confirm-leave.component.css']
})
export class ConfirmLeaveComponent implements OnInit {

  subject: Subject<boolean>;
  orderid = '';
  constructor(public bsModalRef: BsModalRef, private data: DataService) { }
  ngOnInit() {
    this.orderid = this.data.orderId;
  }

  action(value: boolean) {
    this.bsModalRef.hide();
    this.subject.next(value);
    this.subject.complete();
  }

}
