import { Injectable } from '@angular/core';
import { OrderComponent } from './order.component';
import { CanDeactivate } from '@angular/router';
import { DataService } from './data.service';
import { BsModalService } from 'ngx-bootstrap';
import { Subject } from 'rxjs';
import { ConfirmLeaveComponent } from './confirm-leave/confirm-leave.component';

@Injectable({
  providedIn: 'root'
})
export class ConfirmDeactivateGuardService implements CanDeactivate<OrderComponent> {
  
  constructor(private data: DataService, private modalService: BsModalService) { }
  canDeactivate(target: OrderComponent) {

    if(this.data.currentdata && this.data.currentdata.orderStatus === 'Submission In Progress' && !this.data.islogout && this.data.orderLockFlag==='N') {
      const subject = new Subject<boolean>();

      const modal = this.modalService.show(ConfirmLeaveComponent, {'class': 'modal-dialog-primary'});
      modal.content.subject = subject;

      return subject.asObservable();
    } else {
      this.data.islogout = false;
    }
      

      return true;
  }

  
}
