import { Component, OnInit, ViewChild, AfterViewInit, HostListener, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/operator/filter';
import { NavbarComponent } from '../../shared/navbar/navbar.component';
import { Location, PopStateEvent } from '@angular/common';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import PerfectScrollbar from 'perfect-scrollbar';
import {EventBusService} from '../../services/event-bus.service'
@Component({
    selector: 'app-layout',
    templateUrl: './admin-layout.component.html'
})


export class AdminLayoutComponent implements OnInit, AfterViewInit {
    
    private _router: Subscription;
     url: string;
     location: Location;
     private lastPoppedUrl: string;
     private yScrollStack: number[] = [];
     private perfectSideScroll : any;
     private perfectMainScroll : any;

    @ViewChild('sidebar', {static: false}) sidebar;
    @ViewChild(NavbarComponent, {static: false}) navbar: NavbarComponent;
    private elemMainPanel :HTMLElement;
    private elemSidebar :HTMLElement;

    constructor( private router: Router, location:Location,private evtsrvc:EventBusService) {
      this.location = location;
    }

    ngAfterViewInit() {
        const html = document.getElementsByTagName('html')[0];
        this.perfectSideScroll = new PerfectScrollbar(this.elemSidebar);
        this.perfectMainScroll = new PerfectScrollbar(this.elemMainPanel);
        if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
            html.classList.add('perfect-scrollbar-on');
        }
        else {
            html.classList.add('perfect-scrollbar-off');
        }
    }

    ngOnInit() {
     
     this.elemMainPanel = <HTMLElement>document.querySelector('.main-panel');
     this.elemSidebar = <HTMLElement>document.querySelector('.sidebar .sidebar-wrapper');
      this.location.subscribe((ev:PopStateEvent) => {
          this.lastPoppedUrl = ev.url;
      });
       this.router.events.subscribe((event:any) => {
          if (event instanceof NavigationStart) {
             if (event.url != this.lastPoppedUrl)
                 this.yScrollStack.push(window.scrollY);
         } else if (event instanceof NavigationEnd) {
             if (event.url == this.lastPoppedUrl) {
                 this.lastPoppedUrl = undefined;
                 window.scrollTo(0, this.yScrollStack.pop());
             }
         }
      });
      this._router = this.router.events.filter(event => event instanceof NavigationEnd).subscribe((event: NavigationEnd) => {
           this.elemMainPanel.scrollTop = 0;
      });
      this._router = this.router.events.filter(event => event instanceof NavigationEnd).subscribe((event: NavigationEnd) => {
        this.navbar.sidebarClose();
      });
    }
    public isMap(){
        if(this.location.prepareExternalUrl(this.location.path()) == '#/maps/fullscreen'){
            return true;
        }
        else {
            return false;
        }
    }
    isMac(): boolean {
        let bool = false;
        if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
            bool = true;
        }
        return bool;
    }

    onActivateRoute(event: any){
        setTimeout(() => {
            if(this.perfectSideScroll && this.perfectMainScroll){
                this.perfectSideScroll = new PerfectScrollbar(this.elemSidebar);
                this.perfectMainScroll = new PerfectScrollbar(this.elemMainPanel);
                this.perfectSideScroll.update();
                this.perfectMainScroll.update();
            }
        }, 1000);
        
    }
    onScroll($event){
        if($event.target.scrollTop>=55){
            this.evtsrvc.emitScrollStat('scrolled')
        }else{
            this.evtsrvc.emitScrollStat('')  
        }
    }
    
}
