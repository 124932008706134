import { Observable, Subject } from 'rxjs';
import { AddinvestigatorhttpService } from './addinvestigatorhttp.service';
import * as i0 from "@angular/core";
import * as i1 from "./addinvestigatorhttp.service";
var AddinvestigatorService = /** @class */ (function () {
    function AddinvestigatorService(addinvestigatorhttpService) {
        this.addinvestigatorhttpService = addinvestigatorhttpService;
        this.closeSideBarAlert = new Subject();
        this.closeSideBarAlert$ = this.closeSideBarAlert.asObservable();
    }
    AddinvestigatorService.prototype.getAddInvestigatorData = function (sponProjectData) {
        return this.addinvestigatorhttpService.getAddInvestigatorData(sponProjectData);
    };
    AddinvestigatorService.prototype.getEditInvestigatorData = function (requestParams, projectId, sponsorId) {
        return this.addinvestigatorhttpService.getEditInvestigatorData(requestParams, projectId, sponsorId);
    };
    AddinvestigatorService.prototype.getInvestigatorDataExcel = function (sponProjectData) {
        return this.addinvestigatorhttpService.getInvestigatorDataExcel(sponProjectData);
    };
    AddinvestigatorService.prototype.getUpdateInvestigatorData = function (requestParams) {
        return this.addinvestigatorhttpService.updateInvestigatorDetail(requestParams);
    };
    AddinvestigatorService.prototype.closeSideBarDetail = function (flagStatus) {
        this.closeSideBarAlert.next(flagStatus);
    };
    AddinvestigatorService.prototype.saveInvestigatorDetail = function (investigatorData) {
        return this.addinvestigatorhttpService.saveInvestigatorDetail(investigatorData);
    };
    AddinvestigatorService.prototype.deleteInvestigatorSite = function (sponsorProjectData) {
        return this.addinvestigatorhttpService.deleteInvestigatorSite(sponsorProjectData);
    };
    AddinvestigatorService.prototype.sendInvestigatorSiteUserInviteByInvestigatorId = function (requestData) {
        return this.addinvestigatorhttpService.sendInvestigatorSiteUserInviteByInvestigatorId(requestData);
    };
    AddinvestigatorService.prototype.uploadFile = function (sponsorProjectData, file) {
        return this.addinvestigatorhttpService.uploadFile(sponsorProjectData, file);
    };
    AddinvestigatorService.prototype.validateSiteAlreadyExistOrNot = function (siteNumber, projectId) {
        return this.addinvestigatorhttpService.validateSiteAlreadyExistOrNot(siteNumber, projectId);
    };
    AddinvestigatorService.prototype.findNewEmailAddressExistInBiosite = function (investigatorSiteNumber) {
        return this.addinvestigatorhttpService.findNewEmailAddressExistInBiosite(investigatorSiteNumber);
    };
    AddinvestigatorService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AddinvestigatorService_Factory() { return new AddinvestigatorService(i0.ɵɵinject(i1.AddinvestigatorhttpService)); }, token: AddinvestigatorService, providedIn: "root" });
    return AddinvestigatorService;
}());
export { AddinvestigatorService };
