import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "environments/environment";

@Injectable({
    providedIn: 'root'
})
export class RoleService {
    private roles:string[];
    constructor(private httpClient : HttpClient){}

    isRouteAccessible(route:string, permittedRoleForRoute: Array<string>):boolean{
        if(permittedRoleForRoute && permittedRoleForRoute.length>0){
            let isAnyPermittedRolePresent:boolean = false;
            permittedRoleForRoute.forEach(role=> {
                if(this.roles.includes(role)){
                    isAnyPermittedRolePresent = true;
                }
            });
            return isAnyPermittedRolePresent;
        }
        return false;
    }

    setRoles(roles:string[]){
        this.roles = roles;
    }
    
    getRoles(){
        return this.roles;
    }

    isExportToExcelAllowed(projectIds : string[],pageRole : string){
        var params = new HttpParams().set('projectIds',projectIds.join(',')).set('pageRole', pageRole);
        return this.httpClient.get<boolean>(environment.apiUrl + '/api/security/isExportToExcelAllowed',{params})
    }
    
    isUploadDocumentsAllowed(projectId){
        var params = new HttpParams().set('projectId',projectId);
        return this.httpClient.get<boolean>(environment.apiUrl + '/api/security/isUploadDocumentAllowed',{params})
    }

    checkProjectAccessByRole(projectIds: any[], pageRole: string) {
        var params = new HttpParams().set('projectIds',projectIds.join(',')).set('pageRole', pageRole);
        return this.httpClient.get<boolean>(environment.apiUrl + '/api/security/checkProjectAccessByRole',{params})
    }
}