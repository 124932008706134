export const ORDERSTATUSLAYOUT: string ='orderstatuslayout';
export const WORKLISTLAYOUT: string='worklistlayout';
export const INVWORKLISTLAYOUT: string='invworklistlayout';
export const INVENTORYLAYOUT: string ='inventorylayout';
export const ENABLE : string ='enable';
export const DISABLE : string='disable';
export const RESETPASSWORD: string ='resetPassword';
export const INVITEAGAIN: string ='inviteAgain';
export const RESENDCONFIRMATION: string ='resendConfirmation';
export const SPONSOR: string='Sponsor';
export const INVESTIGATOR : string ='Investigator';
export const INVSPONSOR: string='InvSponsor';
export const INVINVESTIGATOR : string ='InvInvestigator'; 
export const NEWORDERPAGE: string ='order';
export const ORDERLISTLAYOUT : string ="orderlistlayout";
export const INVENTORYLISTLAYOUT: string ="inventorylistlayout";
export const ADDSPONSORLAYOUT: string ="addsponsorlayout";
export const ADDINVESTIGATORLAYOUT : string ="addinvestigatorlayout";
export const DATAEXPORTLAYOUT : string="dataexportlayout";
export const SPONSORNAME: string ="SPONSORNAME";
export const LANGUAGECODES: string[] =['en', 'de', 'sp', 'fr', 'ru', 'ch'];
export const LANGUAGES: string[] =['English', 'German', 'Spanish', 'French', 'Russian', 'Chinese'];
export const CUSTOMDATERANGE: string ="CUSTOMDATERANGE";
export const ROLE_NEOPM : string ="ROLE_NEOPM";
export const ROLE_SYSADMIN : string ="ROLE_SYSADMIN";
export const ROLE_DATAMANAGER : string ="ROLE_DATAMANAGER";
export const ROLE_INVPM : string ="ROLE_INVPM";
export const DATAEXPORT: string="DataExport";
export const INVENTORYLIST: string="InventoryList";
export const MANAGESPONSOR: string="ManageSponsor";
export const WORKLIST: string ="WorkList";
export const INVWORKLIST: string ="InvWorkList";
export const ORDERLIST : string ="OrderList";
export const DEFAULTSORT: string="DefaultSortState";
export const SPONSORPROJECTDATA : string ="sponsorProjectData";
export const LAST30DAYS: string ="Last 30 Days";
export const LAST60DAYS: string= "Last 60 Days";
export const LAST90DAYS: string ="Last 90 Days"; 
export const LAST1DAY: string ="Last 1 Day";
export const LAST2DAYS: string= "Last 2 Days";
export const LAST5DAYS: string ="Last 5 Days";
export const LAST10DAYS: string ="Last 10 Days";
export const LAST20DAYS: string ="Last 20 Days";   
export const MO_METHODOLOGY: string ="Multiomyx";
export const CUSTOMDATE: string ="Custom";
export const INVENTORY_LIST_EXPORT_EXCEL = "INVENTORY_LIST_EXPORT_EXCEL";
export const INVENTORY_LIST_DOCS_AND_DATA ="INVENTORY_LIST_DOCS_AND_DATA";
export const WORKLIST_DOCS_AND_DATA="WORKLIST_DOCS_AND_DATA";
export const ORDER_LIST_EXPORT_EXCEL = "ORDER_LIST_EXPORT_EXCEL";
export const WORKLIST_EXPORT_EXCEL = "WORKLIST_EXPORT_EXCEL";
export const INVWORKLIST_EXPORT_EXCEL = "WORKLIST_EXPORT_EXCEL";
export const NO_ROWS_TO_SHOW = "No Rows To Show";
export const EXTERNAL_CLIENT_DETAILS_SAVED = "ExternalClientDetailsSaved";
export const INIVATA = "Inivata";
export const INVPM = "invpm";
export const SIDEBARHEADING={
    HOME_PAGE:"Home Page",
    WORKLIST:"Worklist",
    INVWORKLIST:"INV Worklist",
    INVENTORY_LIST:"Inventory List",
    DATA_EXPORT:"Data Export",
    BULK_DOWNLOAD:"Bulk Download",
    MANAGE_USERS:"Manage Users",
    MANAGE_SPONSOR:"Manage Sponsor",
    MANAGE_INVESTIGATOR:"Manage Investigator",
    ONLINE_ORDERING:"Online Ordering",
    CREATE_NEW_ORDER_GENERIC:"Generic Create Order",
    CREATE_NEW_ORDER_MOL_ASSAY:"Molecular Assay Create Order",
    ORDER_LIST:"Order List",
    SYSTEM_ADMIN:"System Admin",
    MANAGE_EXTERNAL_USERS:"Manage External Users",
    MANAGE_SERVICE_ACCOUNT_USERS:"Manage External Service Account",
    USER_CONNECTIONS:"User Connections",
    DEPLOYMENT_NOTIFICATION : "Deployment Notification",
    DEPLOYMENT_NOTIFICATION_DESC : "- Pacific Standard Time (PST)",
    ENVIRONMENTAL_PROPERTIES:"Environmental Properties",
    MANGE_SERVICE_ACCOUNT:"Manage Service Account",
    PROJECT_SETUP:"Project Access Setup",
    AUDIT_REPORT:"Audit Report",
    ORDER_AUDIT_REPORT:"Order Audit Report",
    REPORT_STATUS:"SFTP Report Transfer Status",
    REPORT_STATUS_DESC:"- SFTP Report Transfer Status",
    HELP:"Help",
    USER_GUIDE:"User Guide",
    MANUAL_SYNC:"Manual Sync",
    EDIT_PROFILE:"Edit Profile",
    CHANGE_PASSWORD:"Change Password",
    DTA : "Data Transfer",
    DTA_REQUEST : "On Demand",
    DTA_AUTOMATED : "Scheduled",
    MANAGE_CLIENT_DETAILS: "Manage Client Details",
    VENDOR_ADMINISTRATION: "Manage External Client",
    SFTP_REPORT_CON_SET:"SFTP_REPORT_CON_SET",
    SFTP_REPORT_CON_DESC:"SFTP_REPORT_CON_DESC",
    SFTP_DETAILS:"SFTP_DETAILS",
    SFTP_DETAILS_DESC: "SFTP_DETAILS_DESC",
    GLOBAL_SEARCH:"Global Search",
    USER_COUNT: "PSP User Count",
    SPONSOR_COUNT: "PSP Sponsor Count",
    PROJECT_COUNT: "PSP Project Count",
    USER_COUNT_DESC: "- List of external users on-boarded in PSP",
    SPONSOR_COUNT_DESC: "- List of sponsors on-boarded in PSP",
    PROJECT_COUNT_DESC: "- List of projects on-boarded in PSP",
    DATA_MANAGER: "Data Transfer",
    MANAGE_DTA:"Data Transfer File Upload",
    DTA_FILE_DOWNLOAD: "Data Transfer File Download",
    DTA_FILE_DOWNLOAD_DESC: "- Download Data Transfer (DTA) Files",
    DTA_FILE_UPLOAD_DESC: "- Upload Data Transfer (DTA) Files",
    DTA_FILE_DOWNLOAD_REPORT: "Data Transfer Files Download Report",
    DTA_FILE_DOWNLOAD_REPORT_DESC: "- Data Transfer (DTA) Files Download Report",
    SFTP_DATA_TRANSFER_STATUS: "SFTP Data Transfer Status",
    SFTP_DATA_TRANSFER_STATUS_DESC: "- SFTP Data Transfer Status",
    INV_COLUMN_CUSTOMIZE:"Inventory Column Customize",
    DATAEXPORT_COLUMN_CUSTOMIZE:"Data Export Column Customize",
    SFTP_INVENTORY_REPORT_DETAILS: "SFTP Inventory Report Transfer Details",
    SFTP_INVENTORY_REPORT_DETAILS_DESC: "- SFTP Inventory Report Transfer Details",
    SFTP_DATAEXPORT_REPORT_DETAILS: "SFTP Data Export Report Transfer Details",
    SFTP_DATAEXPORT_REPORT_DETAILS_DESC: "- SFTP Data Export Report Transfer Details",


}
export const DATE_TIME_FORMAT ={
    TIMESTAMPFORMAT:"YYYY-MM-DD HH:mm:ss.S",
    DEFAUT_START_TIME:"0:0:0.0",
    DEFAULT_END_TIME:"23:59:59.999"
}
 export const LANGUAGE_DISCLAIMER_TEXT = 'Translations of any materials into languages other than English are intended solely as a convenience and are not legally binding. NeoGenomics Laboratories has attempted to provide an accurate translation of the original material in English, but due to the nuances of translation, slight differences may exist.'
 export const ALL: string ="All";

 export const SFTP_SETUP_CATEGORY={
    DTA:"DTA",
    REPORT:"Report",
    INVENTORY:"Inventory",
    DATAEXPORT:"Data Export"
 }

 export const SFTP_SETUP_CONNECTION_SETUP_CATEGORY={
    SFTPPWD:atob("UGFzc3dvcmQ="),
    SSHKEY:"SSH Key",
 }
 export const SFTP_DETAIL_CONNECTION_SETUP_CATEGORY={
    VALUE:atob("UGFzc3dvcmQ="),
    HEADER:"selectConnectionSetUp"
 }
 export const  DATA_EXPORT_DECLAIMER_TEXT ="Data Export Declaimer Text";

 export const INIVATAUSERTYE: string[]= ["invpm","invsponsor","invinvestigator"];