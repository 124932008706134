import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventBusService {
private subject$=new Subject()
  constructor() { }
  emitScrollStat(event){
    this.subject$.next(event)
  }
  getScrollStats():Observable<any>{
    return this.subject$.asObservable()
  }
}
