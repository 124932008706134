import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from 'environments/environment';
import { Subject } from "rxjs";
import { CLIENT, CLIENT_CRED, TOKEN_NAME, REFRESH_TOKEN_NAME, INTERNAL_USER_LOGOUT_URL } from "./auth.constant";
import { JwtHelperService } from "@auth0/angular-jwt";
import { Router } from "@angular/router";
import { RoleService } from "./guard/role.service";
import { DataService } from "app/order/data.service";
import { SPONSORPROJECTDATA } from '../shared/pharmaportal.constant';
import { StorageService } from "./storage.service";
import { SponsorProjectDropdownService } from "app/shared/components/sponsor-project-dropdown/sponsor-project-dropdown.service";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/router";
import * as i3 from "./guard/role.service";
import * as i4 from "../order/data.service";
import * as i5 from "./storage.service";
import * as i6 from "../shared/components/sponsor-project-dropdown/sponsor-project-dropdown.service";
var LoginService = /** @class */ (function () {
    function LoginService(http, router, roleService, data, storageService, dropdownService) {
        this.http = http;
        this.router = router;
        this.roleService = roleService;
        this.data = data;
        this.storageService = storageService;
        this.dropdownService = dropdownService;
        this.baseUrl = environment.apiUrl;
        this.lastValidityCheckTimeStamp = null;
        this.decodedToken = null;
        this.dataExport_DtoReportObject = [];
        this.jwtHelper = new JwtHelperService({
            tokenGetter: this.getAccessToken.bind(this)
        });
        this.subject = new Subject();
    }
    LoginService.prototype.login = function (username, password) {
        var body = "username=" + encodeURIComponent(username) + "&password=" + encodeURIComponent(password) + "&grant_type=password";
        var data = new FormData();
        data.append('username', username);
        data.append('password', password);
        data.append('grant_type', 'password');
        data.append('Authorization', 'Basic ' + btoa(CLIENT + ':' + CLIENT_CRED));
        return this.http.post(this.baseUrl + LoginService.TOKEN_ENDPOINT, data);
    };
    LoginService.prototype.logout = function () {
        var data = new FormData();
        data.append('token', this.getAccessToken());
        return this.http.post(this.baseUrl + "/api/security/logout", data, { responseType: 'text' });
    };
    LoginService.prototype.getEnvironment = function () {
        return this.http.get(this.baseUrl + "/api/security/getAppEnvironment");
    };
    LoginService.prototype.setUserDetails = function (users) {
        this.userDetails = users;
        this.lastValidityCheckTimeStamp = new Date();
    };
    LoginService.prototype.clearUserDetails = function () {
        this.userDetails = null;
        this.lastValidityCheckTimeStamp = null;
    };
    LoginService.prototype.initiateAccessToken = function (access_token) {
        this.setAccessToken(access_token);
        var decodedToken = this.jwtHelper.decodeToken(access_token);
        this.decodedToken = decodedToken;
        sessionStorage.setItem('loggedUser', decodedToken.user_name);
        sessionStorage.setItem('loggedUserType', decodedToken.user_type);
        this.roleService.setRoles(this.decodedToken.authorities);
        return decodedToken;
    };
    LoginService.prototype.isLoggedIn = function () {
        return !this.jwtHelper.isTokenExpired();
    };
    LoginService.prototype.getTokenExpirationtime = function () {
        if (this.decodedToken === undefined)
            return null;
        var expDate = new Date(0);
        expDate.setUTCSeconds(this.decodedToken.exp);
        var seconds = (expDate.getTime() - new Date().getTime()) / 1000;
        return seconds;
    };
    LoginService.prototype.isRefreshTokenValid = function () {
        var helper = new JwtHelperService();
        var refreshToken = sessionStorage.getItem('refresh_token');
        return helper.isTokenExpired(refreshToken);
    };
    LoginService.prototype.getJWTHepler = function () {
        return this.jwtHelper;
    };
    LoginService.prototype.performInitialNavigation = function () {
        if (this.decodedToken) {
            if (this.decodedToken.user_type === 'sysadmin') {
                this.router.navigate(['/systemAdmin/manageExternalUsers']);
            }
            else if (this.decodedToken.user_type === 'datamanager') {
                this.router.navigate(['/dataManager/manageDTA']);
            }
            else {
                this.router.navigate(['/reports/maindashboard']);
            }
        }
        else {
            this.router.navigate(['/pages/login']);
        }
    };
    LoginService.prototype.getAccessToken = function () {
        return this.storageService.getItem(TOKEN_NAME);
    };
    LoginService.prototype.setAccessToken = function (token) {
        this.storageService.setItem(TOKEN_NAME, token);
    };
    LoginService.prototype.getRefreshToken = function () {
        return this.storageService.getItem(REFRESH_TOKEN_NAME);
    };
    LoginService.prototype.setRefreshToken = function (token) {
        this.storageService.setItem('refresh_token', token);
    };
    LoginService.prototype.setInternalUserLogoutURL = function (url) {
        this.storageService.setItem(INTERNAL_USER_LOGOUT_URL, url);
    };
    LoginService.prototype.logoutAndDestroyToken = function () {
        var _this = this;
        this.propagateLoginSuccess(false);
        this.logout().subscribe(function (data) {
            _this.navigateAndDestroyToken();
        }, function (error) {
            _this.navigateAndDestroyToken();
        });
    };
    LoginService.prototype.navigateAndDestroyToken = function () {
        this.data.islogout = true;
        sessionStorage.removeItem(SPONSORPROJECTDATA);
        this.storageService.removeItem(TOKEN_NAME);
        this.storageService.removeItem(REFRESH_TOKEN_NAME);
        sessionStorage.removeItem('loggedUser');
        if (sessionStorage.getItem('loggedUserType') === 'neopm' || sessionStorage.getItem('loggedUserType') === 'sysadmin') {
            sessionStorage.removeItem('loggedUserType');
            var internalUserLogoutUrl = this.storageService.getItem(INTERNAL_USER_LOGOUT_URL);
            window.location.href = internalUserLogoutUrl;
        }
        else {
            sessionStorage.removeItem('loggedUserType');
            this.router.navigate(['/pages/login']);
        }
        ;
    };
    LoginService.prototype.refreshToken = function () {
        var data = new FormData();
        data.append('client_id', CLIENT);
        data.append('client_secret', CLIENT_CRED);
        data.append('grant_type', encodeURIComponent('refresh_token'));
        data.append('refresh_token', this.getRefreshToken());
        data.append('Authorization', 'Basic ' + btoa(CLIENT + ':' + CLIENT_CRED));
        return this.http.post(this.baseUrl + LoginService.TOKEN_ENDPOINT, data);
    };
    LoginService.prototype.propagateLoginSuccess = function (isLoggedin) {
        this.subject.next(isLoggedin);
    };
    LoginService.prototype.loginObservable = function () {
        return this.subject.asObservable();
    };
    LoginService.prototype.getLoggedInUserName = function () {
        this.decodedToken = this.jwtHelper.decodeToken(this.getAccessToken());
        return this.decodedToken.user_name;
    };
    LoginService.prototype.getUserGuide = function () {
        var httpOptions = {
            responseType: 'arraybuffer'
        };
        return this.http.get(this.baseUrl + "/api/dashboard/getUserGuide", httpOptions);
    };
    LoginService.prototype.forgetPassword = function (username) {
        return this.http.get(this.baseUrl + "/api/user/reset/" + username + "/anonymousUser");
    };
    LoginService.prototype.getSecureAuthProperties = function () {
        return this.http.get(environment.apiUrl + '/api/security/getSecureAuthProperties');
    };
    LoginService.prototype.dataexport_DtaReportId = function (url, reqReportId, requestCategory) {
        this.dataExport_DtoReportObject = {
            "url": url,
            "reqReportId": reqReportId,
            "requestCategory": requestCategory
        };
    };
    LoginService.prototype.dataexport_DtaReportReturn = function () {
        return this.dataExport_DtoReportObject;
    };
    LoginService.prototype.accountConfirm = function (token) {
        var data = new FormData();
        return this.http.post(this.baseUrl + "/api/user/checkuniqueConfirmId/" + token, data);
    };
    LoginService.prototype.loadBgVideo = function () {
        var httpOptions = {
            responseType: 'blob'
        };
        return this.http.get(this.baseUrl + "/api/security/loadBgVideo", httpOptions);
    };
    LoginService.prototype.generateToken = function (userType) {
        var params = new HttpParams();
        params = params.append('userType', userType);
        params = params.append('token', this.getAccessToken());
        this.dropdownService.resetObservable();
        sessionStorage.removeItem("sponsorProjectData");
        return this.http.get(environment.apiUrl + '/api/security/generateToken', { params: params, responseType: 'text' });
    };
    LoginService.prototype.fetchUserRoles = function () {
        return this.http.get(environment.apiUrl + '/api/security/fetchUserRoles');
    };
    LoginService.prototype.getDynamicUrl = function () {
        return this.http.get(environment.apiUrl + '/api/security/fetchDynamicUrl', { responseType: 'text' });
    };
    LoginService.TOKEN_ENDPOINT = '/oauth/token';
    LoginService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoginService_Factory() { return new LoginService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.RoleService), i0.ɵɵinject(i4.DataService), i0.ɵɵinject(i5.StorageService), i0.ɵɵinject(i6.SponsorProjectDropdownService)); }, token: LoginService, providedIn: "root" });
    return LoginService;
}());
export { LoginService };
