import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NavbarComponent } from './navbar.component';
import  {SharedModule} from  '../../shared/shared.module';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AppComponent } from '../../app.component';


@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        SharedModule,
        // configure the imports
        HttpClientModule,
    ],
    declarations: [NavbarComponent],
    exports: [NavbarComponent],
    providers: [
        AppComponent
       ]
})

export class NavbarModule { }

