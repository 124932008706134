import { DataService } from './data.service';
import { BsModalService } from 'ngx-bootstrap';
import { Subject } from 'rxjs';
import { ConfirmLeaveComponent } from './confirm-leave/confirm-leave.component';
import * as i0 from "@angular/core";
import * as i1 from "./data.service";
import * as i2 from "ngx-bootstrap/modal";
var ConfirmDeactivateGuardService = /** @class */ (function () {
    function ConfirmDeactivateGuardService(data, modalService) {
        this.data = data;
        this.modalService = modalService;
    }
    ConfirmDeactivateGuardService.prototype.canDeactivate = function (target) {
        if (this.data.currentdata && this.data.currentdata.orderStatus === 'Submission In Progress' && !this.data.islogout && this.data.orderLockFlag === 'N') {
            var subject = new Subject();
            var modal = this.modalService.show(ConfirmLeaveComponent, { 'class': 'modal-dialog-primary' });
            modal.content.subject = subject;
            return subject.asObservable();
        }
        else {
            this.data.islogout = false;
        }
        return true;
    };
    ConfirmDeactivateGuardService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ConfirmDeactivateGuardService_Factory() { return new ConfirmDeactivateGuardService(i0.ɵɵinject(i1.DataService), i0.ɵɵinject(i2.BsModalService)); }, token: ConfirmDeactivateGuardService, providedIn: "root" });
    return ConfirmDeactivateGuardService;
}());
export { ConfirmDeactivateGuardService };
