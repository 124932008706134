import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders,HttpParams } from '@angular/common/http';
import { catchError} from 'rxjs/operators';
import { Observable} from 'rxjs';
import { environment } from 'environments/environment';
import {ErrorHandlerService } from '../services/error-handler.service';
import { HttpErrorResponse } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class AddinvestigatorhttpService {

  private baseUrl = environment.apiUrl;
  private httpOptions: any;

  constructor(private http: HttpClient, private errorHandlerService: ErrorHandlerService) {

    this.httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
  }

  getAddInvestigatorData(sponProjectData: any): Observable<any> {
    let tempObj = {
      tempSponsorId: sponProjectData.sponsorData.id,
      tempProjectId: sponProjectData.projectData.id
    }
    let jsonRequest = JSON.stringify(tempObj, null, 2);
    let urlData = this.baseUrl + "/api/investigator/getRows"
    return this.http.post(urlData, jsonRequest, this.httpOptions)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          return this.errorHandlerService.errorHandlerWithTranslator(error);
      }));
  }

  getEditInvestigatorData(investigatorPid: any, projectId: string, sponsorId: string): Observable<any> {
    let urlData = this.baseUrl + "/api/investigator/getInvestigatorDetail"
    return this.http.get<any>(urlData, {
      params: {
        investigatorId: investigatorPid,
        projectId: projectId,
        sponsorId:sponsorId
      }
    })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          return this.errorHandlerService.errorHandlerWithTranslator(error);
        })
      );
  }

  getInvestigatorDataExcel(sponProjectData: any): Observable<any> {
    let tempObj = {
      tempSponsorId: sponProjectData.sponsorData.id,
      tempProjectId: sponProjectData.projectData.id
    }
    let jsonRequest = JSON.stringify(tempObj, null, 2);
    let urlData = this.baseUrl + "/api/investigator/importInvestigatorExcel"
    return this.http.post(urlData, jsonRequest, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      responseType: 'blob'
    })
  }

  saveInvestigatorDetail(investigatorData: any): Observable<any> {
    let jsonRequest = JSON.stringify(investigatorData, null, 2);
    let urlData = this.baseUrl + "/api/investigator/saveInvestigatorData"
    return this.http.post(urlData, jsonRequest, this.httpOptions)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          return this.errorHandlerService.errorHandlerWithTranslator(error);
        })
      );
  }

  updateInvestigatorDetail(investigatorData: any): Observable<any> {
    let jsonRequest = JSON.stringify(investigatorData, null, 2);
    let urlData = this.baseUrl + "/api/investigator/updateInvestigatorData"

    return this.http.post(urlData, jsonRequest, this.httpOptions)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          return this.errorHandlerService.errorHandlerWithTranslator(error);
        })
      );
  }

  deleteInvestigatorSite(sponsorProjectData: any): Observable<any> {
    let urlData = this.baseUrl + "/api/investigator/deleteInvestigatorSite";
    let jsonRequest = JSON.stringify(sponsorProjectData, null, 2);
    return this.http.post(urlData, jsonRequest, this.httpOptions)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          return this.errorHandlerService.errorHandlerWithTranslator(error);
        })
      );
  }

  sendInvestigatorSiteUserInviteByInvestigatorId(requestData: Object): Observable<any> {
    let jsonRequest = JSON.stringify(requestData, null, 2);
    let urlData = this.baseUrl + "/api/investigator/sendInvestigatorInvite"
    return this.http.post(urlData, jsonRequest, this.httpOptions)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          return this.errorHandlerService.errorHandlerWithTranslator(error);
        })
      );
  }

  uploadFile(sponsorProjectData, file: File): Observable<any> {
    const data: FormData = new FormData();
    data.append('sponsorId', sponsorProjectData.sponsorData.id);
    data.append('projectId', sponsorProjectData.projectData.id);
    data.append('uploadedFile', file, file.name);
    let urlData = this.baseUrl + "/api/investigator/bulkUpload" ;
    return this.http.post(urlData, data, {
      responseType: 'json'
    });
  }

  validateSiteAlreadyExistOrNot(siteNumber : string, projectId :string): Observable<any>{
    
    return this.http.post(environment.apiUrl+'/api/investigator/checkSiteAlreadyExistOrNot',null,{
      params: {
              siteNumber: siteNumber,
              projectId: projectId
            }
    }).pipe(
      catchError((error: HttpErrorResponse) => {
        return this.errorHandlerService.errorHandlerWithTranslator(error);
      })
    );
  }

  findNewEmailAddressExistInBiosite(investigatorSiteNumber: string){
    var params = new HttpParams().set("investigatorPid",investigatorSiteNumber);
      return this.http.get<any>(environment.apiUrl+'/api/investigator/checkNewEmailExist', {params})
      .pipe(catchError((error: HttpErrorResponse) => {
        return this.errorHandlerService.errorHandlerWithTranslator(error);
      }))
  }

}
