import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "environments/environment";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var RoleService = /** @class */ (function () {
    function RoleService(httpClient) {
        this.httpClient = httpClient;
    }
    RoleService.prototype.isRouteAccessible = function (route, permittedRoleForRoute) {
        var _this = this;
        if (permittedRoleForRoute && permittedRoleForRoute.length > 0) {
            var isAnyPermittedRolePresent_1 = false;
            permittedRoleForRoute.forEach(function (role) {
                if (_this.roles.includes(role)) {
                    isAnyPermittedRolePresent_1 = true;
                }
            });
            return isAnyPermittedRolePresent_1;
        }
        return false;
    };
    RoleService.prototype.setRoles = function (roles) {
        this.roles = roles;
    };
    RoleService.prototype.getRoles = function () {
        return this.roles;
    };
    RoleService.prototype.isExportToExcelAllowed = function (projectIds, pageRole) {
        var params = new HttpParams().set('projectIds', projectIds.join(',')).set('pageRole', pageRole);
        return this.httpClient.get(environment.apiUrl + '/api/security/isExportToExcelAllowed', { params: params });
    };
    RoleService.prototype.isUploadDocumentsAllowed = function (projectId) {
        var params = new HttpParams().set('projectId', projectId);
        return this.httpClient.get(environment.apiUrl + '/api/security/isUploadDocumentAllowed', { params: params });
    };
    RoleService.prototype.checkProjectAccessByRole = function (projectIds, pageRole) {
        var params = new HttpParams().set('projectIds', projectIds.join(',')).set('pageRole', pageRole);
        return this.httpClient.get(environment.apiUrl + '/api/security/checkProjectAccessByRole', { params: params });
    };
    RoleService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RoleService_Factory() { return new RoleService(i0.ɵɵinject(i1.HttpClient)); }, token: RoleService, providedIn: "root" });
    return RoleService;
}());
export { RoleService };
